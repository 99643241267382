/* * 업무구분: 고객 * 화 면 명: MSPFS108M * 화면설명: 고객정보정정1500 * 작 성 일: 2023.06.09 * 작 성 자: 이태흥 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->

    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left gap40">
            <div class="wrap-input row">
              <label class="emphasis"> 고객번호 </label>
              <mo-decimal-field
                numeric
                ref="ed_cust_no"
                class="mw150"
                :class="lv_ed_cust_no.error ? 'error' : ''"
                v-model="lv_ed_cust_no.value"
                maxlength="7"
                @keyup="fn_SearchValid($event)"
                @input="fn_clear('appointCust')"
                mask="#######"
                :disabled="lv_ed_cust_no.disabled"
              />
              <mo-button class="w61" @click="fn_OpenPopup('MSPFS308P')"> 고객정보 </mo-button>
              <mo-text-field class="w130" v-model="lv_ed_cust_nm.value" disabled />
            </div>
            <div class="wrap-input row">
              <label class="emphasis"> 대리인여부 </label>
              <mo-radio-wrapper :class="lv_rdo_agnt_chk_yn.error ? 'error' : ''" :items="itemsAgent" v-model="lv_rdo_agnt_chk_yn.value" @input="fn_clear('agnt_chk')" class="row" />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button @click="fn_Init()"> 초기화 </mo-button>
              <mo-button primary @click="fn_searchList()"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- 기본 정보 - 기본 공통 //-->
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title flex-sb">
          <h2 class="table-title">기본정보</h2>
          <span v-if="lv_cust_info_base.visibled">
            {{ lv_cust_info_base.value }}
          </span>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col1634">
            <tbody>
              <tr>
                <th>
                  <span> 실명번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      v-if="lv_corp_gb == 'N'"
                      ref="ed_rname_no"
                      class="input-long"
                      :class="lv_ed_rname_no.error ? 'error' : ''"
                      v-model="lv_ed_rname_no.value"
                      :disabled="lv_ed_rname_no.disabled"
                      maxlength="14"
                    />
                    <mo-text-field v-if="lv_corp_gb == 'Y'" class="input-long" type="number" v-model="lv_ed_rname_no1.value" :disabled="lv_ed_rname_no1.disabled" maxlength="14" />
                    <mo-button v-if="lv_img_info_text" primary class="w90" @click="fn_img_info_text_onclick()">정보노출고객</mo-button>
                  </div>
                </td>
                <th colspan="2">
                  <span> 실명번호종류 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :class="lv_cob_rname_gb.error ? 'error' : ''"
                      :items="lv_commCode.code211"
                      v-model="lv_cob_rname_gb.value"
                      :disabled="lv_cob_rname_gb.disabled"
                      @input="fn_CU1500_rname_chk()"
                      placeholder="선택하세요"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 고객명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field :class="lv_cust_nm.error ? 'error' : ''" class="input-long" v-model="lv_cust_nm.value" :disabled="lv_cust_nm.disabled" />
                  </div>
                </td>
                <th colspan="2">
                  <span> 고객영문명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_cust_enm.value" :disabled="lv_cust_enm.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 국적 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :class="lv_cob_ctzn_natn_cd.error ? 'error' : ''" :items="lv_commCode.code981" v-model="lv_cob_ctzn_natn_cd.value" :disabled="lv_cob_ctzn_natn_cd.disabled" />
                  </div>
                </td>
                <th colspan="2">
                  <span> 거주국 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :class="lv_cob_resi_natn_cd.error ? 'error' : ''" :items="lv_commCode.code981" v-model="lv_cob_resi_natn_cd.value" :disabled="lv_cob_resi_natn_cd.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 소득자구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :class="lv_cob_inc_earner_gb.error ? 'error' : ''"
                      :items="lv_commCode.code206"
                      v-model="lv_cob_inc_earner_gb.value"
                      :disabled="lv_cob_inc_earner_gb.disabled"
                      @input="fn_CU1500_rname_chk2()"
                    />
                  </div>
                </td>
                <!-- [D] 개인1 -->
                <th colspan="2" v-if="lv_corp_gb == 'N'">
                  <span> 거주자여부 </span>
                </th>
                <td v-if="lv_corp_gb == 'N'">
                  <div class="wrap-input row ml85">
                    <mo-radio-wrapper :class="lv_rad_resi_yn.error ? 'error' : ''" :items="residentPerson" v-model="lv_rad_resi_yn.value" class="row" :disabled="lv_rad_resi_yn.disabled" />
                  </div>
                </td>
                <!-- [D] 법인1 -->
                <th colspan="2" v-if="lv_corp_gb == 'Y'">
                  <span> 대표자명 </span>
                </th>
                <td v-if="lv_corp_gb == 'Y'">
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_ed_res_nm.value" :disabled="lv_ed_res_nm.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <!-- [D] 개인2 -->
                <th v-if="lv_corp_gb == 'N'">
                  <span
                    >우대구분<br />
                    (생계형/세금우대)
                  </span>
                </th>
                <td v-if="lv_corp_gb == 'N'">
                  <div class="wrap-input row">
                    <mo-dropdown
                      :class="lv_cob_life_gb.error ? 'error' : ''"
                      :items="lv_commCode.code210"
                      v-model="lv_cob_life_gb.value"
                      :disabled="lv_cob_life_gb.disabled"
                      @input="fn_cob_life_gb()"
                    />
                  </div>
                </td>
                <!-- [D] 법인2 -->
                <th v-if="lv_corp_gb == 'Y'">
                  <span> 부기명/부서명 </span>
                </th>
                <td v-if="lv_corp_gb == 'Y'">
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_ed_addrmrk_nm.value" :disabled="lv_ed_addrmrk_nm.disabled" />
                  </div>
                </td>
                <th rowspan="4" class="w80">
                  <span class="emphasis"> 신용정보 동의여부 </span>
                </th>
                <td class="w90 border-left">
                  <span class="label-sub"> 계약체결 </span>
                </td>
                <td>
                  <div class="wrap-input row">
                    <mo-radio-wrapper :items="creditAgree" v-model="lv_rad_crd_int_yn1.value" :disabled="lv_rad_crd_int_yn1.disabled" @input="fn_rad_crd_int_yn1()" />
                  </div>
                </td>
              </tr>
              <tr>
                <!-- [D] 개인3 -->
                <th v-if="lv_corp_gb == 'N'">
                  <span> 생일 </span>
                </th>
                <td v-if="lv_corp_gb == 'N'">
                  <div class="wrap-input row">
                    <div class="picker row">
                      <mo-date-picker v-model="lv_cal_birth_dt.value" :bottom="false" class="w110" :disabled="lv_cal_birth_dt.disabled" placeholder=" " />
                    </div>
                    <div class="radio row">
                      <mo-radio-wrapper :items="lv_commCode.code205" v-model="lv_rad_birth_solun_gb.value" class="row" :disabled="lv_rad_birth_solun_gb.disabled" />
                    </div>
                  </div>
                </td>
                <!-- [D] 법인3 -->
                <th v-if="lv_corp_gb == 'Y'">
                  <span> 우편물통보처 </span>
                </th>
                <td v-if="lv_corp_gb == 'Y'">
                  <div>
                    <mo-dropdown
                      :class="lv_rad_mail_recv_gb.error ? 'error' : ''"
                      :items="lv_commCode.code221"
                      v-model="lv_rad_mail_recv_gb.value"
                      placeholder="선택하세요"
                      :disabled="lv_rad_mail_recv_gb.disabled"
                      @input="fn_rad_mail_recv_gb()"
                    />
                  </div>
                </td>
                <td class="w90 border-left">
                  <span class="label-sub"> 마케팅 </span>
                </td>
                <td>
                  <!-- 신용정보 동의여부2 -->
                  <div class="wrap-input row">
                    <mo-radio-wrapper
                      :class="lv_rad_crd_int_yn2.error ? 'error' : ''"
                      :items="marketingAgree"
                      v-model="lv_rad_crd_int_yn2.value"
                      @input="fn_rad_crd_int_yn2()"
                      :disabled="lv_rad_crd_int_yn2.disabled"
                    />
                    <span v-if="lv_rad_crd_int_yn2.value == 'Y'">
                      (종료일자
                      <mo-date-picker v-model="lv_mask_crd_endymd.value" :disabled="lv_mask_crd_endymd.disabled" :bottom="false" class="w104 mr5" />
                      )
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <!-- [D] 개인4 -->
                <th v-if="lv_corp_gb == 'N'">
                  <span> 우편물통보처 </span>
                </th>
                <td v-if="lv_corp_gb == 'N'">
                  <div>
                    <mo-dropdown
                      :class="lv_rad_mail_recv_gb.error ? 'error' : ''"
                      :items="lv_commCode.code221"
                      v-model="lv_rad_mail_recv_gb.value"
                      placeholder="선택하세요"
                      :disabled="lv_rad_mail_recv_gb.disabled"
                      @input="fn_rad_mail_recv_gb()"
                    />
                  </div>
                </td>
                <!-- [D] 법인4 -->
                <th v-if="lv_corp_gb == 'Y'">
                  <span> 펀드잔고통보처 </span>
                </th>
                <td v-if="lv_corp_gb == 'Y'">
                  <div class="wrap-input row">
                    <mo-radio-wrapper
                      :class="lv_rad_fund_recv_gb.error ? 'error' : ''"
                      :items="lv_commCode.code223"
                      v-model="lv_rad_fund_recv_gb.value"
                      :disabled="lv_rad_fund_recv_gb.disabled"
                      @input="fn_rad_fund_recv_gb()"
                    />
                  </div>
                </td>
                <td class="w90 border-left" rowspan="2">
                  <span class="label-sub"> 연락방식 </span>
                </td>

                <td rowspan="2">
                  <div class="wrap-input row">
                    <div class="wrap-rowspan">
                      <div class="row wrap-switch">
                        <div class="row">
                          <div class="row">
                            <span class="title-switch"> 전화 </span>
                            <mo-switch v-model="lv_cob_mkt_cnvs_mth_tel_yn.value" class="switch-t1" :disabled="lv_cob_mkt_cnvs_mth_tel_yn.disabled" />
                          </div>
                          <div class="row">
                            <span class="title-switch wS"> 문자 </span>
                            <mo-switch v-model="lv_cob_mkt_cnvs_mth_sms_yn.value" class="switch-t1" :disabled="lv_cob_mkt_cnvs_mth_sms_yn.disabled" />
                          </div>
                        </div>
                      </div>
                      <div class="row wrap-switch">
                        <div class="row">
                          <span class="title-switch"> E-mail </span>
                          <mo-switch v-model="lv_cob_mkt_cnvs_mth_email_yn.value" class="switch-t1" :disabled="lv_cob_mkt_cnvs_mth_email_yn.disabled" />
                        </div>
                        <div class="row">
                          <span class="title-switch wS"> 우편 </span>
                          <mo-switch v-model="lv_cob_mkt_cnvs_mth_pst_yn.value" class="switch-t1" :disabled="lv_cob_mkt_cnvs_mth_pst_yn.disabled" />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <!-- [D] 개인5 -->
                <th v-if="lv_corp_gb == 'N'">
                  <span> 펀드잔고통보처 </span>
                </th>
                <td v-if="lv_corp_gb == 'N'">
                  <div class="wrap-input row">
                    <mo-radio-wrapper
                      :class="lv_rad_fund_recv_gb.error ? 'error' : ''"
                      :items="lv_commCode.code223"
                      v-model="lv_rad_fund_recv_gb.value"
                      :disabled="lv_rad_fund_recv_gb.disabled"
                      @input="fn_rad_fund_recv_gb()"
                    />
                  </div>
                </td>
                <!-- [D] 법인5 -->
                <th v-if="lv_corp_gb == 'Y'">
                  <span> 과세구분 </span>
                </th>
                <td v-if="lv_corp_gb == 'Y'">
                  <div>
                    <mo-dropdown :items="lv_commCode.code202" v-model="lv_cob_tax_gb.value" placeholder="선택하세요" :disabled="lv_cob_tax_gb.disabled" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title">연락처정보</h2>
          <span class="fs12 colorGray">※ 고객정보 통합 미동의인 경우 보험ERP 고객정보와 수익증권 내 고객정보가 상이 할 수 있습니다.</span>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col1634">
            <tbody>
              <tr>
                <th rowspan="2">
                  <span :class="lv_h_addr_p ? 'emphasis' : ''"> 자택주소 {{ lv_ed_h_addr_zipcode_text }}</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field :class="lv_ed_h_addr_zipcode_new.error ? 'error' : ''" class="w60" v-model="lv_ed_h_addr_zipcode_new.value" :disabled="lv_ed_h_addr_zipcode_new.disabled" />
                    <mo-button class="btn-zip" :disabled="lv_chk_del_h_addr.value" @click="fn_OpenPopup(0, 'HOME')"> 우편번호 </mo-button>
                    <mo-checkbox v-model="lv_chk_del_h_addr.value" class="row"> 삭제 </mo-checkbox>
                    <div class="row wrap-switch2">
                      <span> 주소표준화여부 </span>
                      <i>{{ lv_ed_h_addr_stdzt_yn.value }}</i>
                    </div>
                  </div>
                </td>
                <th>
                  <span> 자택전화번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="ed_h_telno_area"
                      :class="lv_ed_h_telno_area.error ? 'error' : ''"
                      v-model.trim="lv_ed_h_telno_area.value"
                      :disabled="lv_ed_h_telno_area.disabled"
                      maxlength="3"
                      type="number"
                    />
                    <mo-text-field
                      ref="ed_h_telno_station"
                      :class="lv_ed_h_telno_station.error ? 'error' : ''"
                      v-model.trim="lv_ed_h_telno_station.value"
                      :disabled="lv_ed_h_telno_station.disabled"
                      maxlength="4"
                      type="number"
                    />
                    <mo-text-field
                      ref="ed_h_telno_seq"
                      :class="lv_ed_h_telno_seq.error ? 'error' : ''"
                      v-model.trim="lv_ed_h_telno_seq.value"
                      :disabled="lv_ed_h_telno_seq.disabled"
                      maxlength="4"
                      type="number"
                    />
                    <mo-checkbox v-model="lv_chk_del_h_telno.value" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field :class="lv_ed_h_zip_addr.error ? 'error' : ''" v-model="lv_ed_h_zip_addr.value" :disabled="lv_ed_h_zip_addr.disabled" class="input-long" />
                    <mo-text-field :class="lv_ed_h_bldg_no.error ? 'error' : ''" v-model="lv_ed_h_bldg_no.value" :disabled="lv_ed_h_bldg_no.disabled" class="w68" />
                    <mo-text-field :class="lv_ed_h_remn_addr.error ? 'error' : ''" v-model="lv_ed_h_remn_addr.value" :disabled="lv_ed_h_remn_addr.disabled" class="input-long" />
                    <mo-text-field :class="lv_ed_h_adtn_addr.error ? 'error' : ''" v-model="lv_ed_h_adtn_addr.value" :disabled="lv_ed_h_adtn_addr.disabled" class="input-long" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>직장명</th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field ref="ed_o_name" class="w230" :class="lv_ed_o_name.error ? 'error' : ''" v-model="lv_ed_o_name.value" :disabled="lv_ed_o_name.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th rowspan="2">
                  <span :class="lv_o_addr_p ? 'emphasis' : ''"> 직장주소 {{ lv_ed_o_addr_zipcode_text }}</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field :class="lv_ed_o_addr_zipcode_new.error ? 'error' : ''" class="w60" v-model="lv_ed_o_addr_zipcode_new.value" :disabled="lv_ed_o_addr_zipcode_new.disabled" />
                    <mo-button class="btn-zip" :disabled="lv_chk_del_o_addr.value" @click="fn_OpenPopup(0, 'JOB')"> 우편번호 </mo-button>
                    <mo-checkbox v-model="lv_chk_del_o_addr.value" :disabled="lv_chk_del_o_addr.disabled" class="row"> 삭제 </mo-checkbox>
                    <div class="row wrap-switch2">
                      <span> 주소표준화여부 </span>
                      <i> {{ lv_ed_o_addr_stdzt_yn.value }} </i>
                    </div>
                  </div>
                </td>
                <th>
                  <span :class="lv_o_addr_p ? 'emphasis' : ''"> 직장전화번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="ed_o_telno_area"
                      :class="lv_ed_o_telno_area.error ? 'error' : ''"
                      v-model.trim="lv_ed_o_telno_area.value"
                      :disabled="lv_ed_o_telno_area.disabled"
                      maxlength="3"
                      type="number"
                    />
                    <mo-text-field
                      ref="ed_o_telno_station"
                      :class="lv_ed_o_telno_station.error ? 'error' : ''"
                      v-model.trim="lv_ed_o_telno_station.value"
                      :disabled="lv_ed_o_telno_station.disabled"
                      maxlength="4"
                      type="number"
                    />
                    <mo-text-field
                      ref="ed_o_telno_seq"
                      :class="lv_ed_o_telno_seq.error ? 'error' : ''"
                      v-model.trim="lv_ed_o_telno_seq.value"
                      :disabled="lv_ed_o_telno_seq.disabled"
                      maxlength="4"
                      type="number"
                    />
                    <mo-checkbox v-model="lv_chk_del_o_telno.value" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field :class="lv_ed_o_zip_addr.error ? 'error' : ''" v-model="lv_ed_o_zip_addr.value" :disabled="lv_ed_o_zip_addr.disabled" class="input-long" />
                    <mo-text-field :class="lv_ed_o_bldg_no.error ? 'error' : ''" v-model="lv_ed_o_bldg_no.value" :disabled="lv_ed_o_bldg_no.disabled" class="w68" />
                    <mo-text-field :class="lv_ed_o_remn_addr.error ? 'error' : ''" v-model="lv_ed_o_remn_addr.value" :disabled="lv_ed_o_remn_addr.disabled" class="input-long" />
                    <mo-text-field :class="lv_ed_o_adtn_addr.error ? 'error' : ''" v-model="lv_ed_o_adtn_addr.value" :disabled="lv_ed_o_adtn_addr.disabled" class="input-long" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> FAX번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model.trim="lv_ed_faxno_area.value" :disabled="lv_ed_faxno_area.disabled" maxlength="3" type="number" />
                    <mo-text-field v-model.trim="lv_ed_faxno_station.value" :disabled="lv_ed_faxno_station.disabled" maxlength="4" type="number" />
                    <mo-text-field v-model.trim="lv_ed_faxno_seq.value" :disabled="lv_ed_faxno_seq.disabled" maxlength="4" type="number" />
                    <mo-checkbox v-model="lv_chk_del_faxno.value" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
                <th>
                  <span :class="lv_p_addr_p ? 'emphasis' : ''"> 휴대폰번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="phoneNum"
                      v-model.trim="lv_cob_cp_telno_comp.value"
                      class="w80"
                      :class="lv_cob_cp_telno_comp.error ? 'error' : ''"
                      :disabled="lv_cob_cp_telno_comp.disabled"
                      placeholder=" "
                    />
                    <mo-text-field
                      ref="ed_cp_telno_station"
                      :class="lv_ed_cp_telno_station.error ? 'error' : ''"
                      v-model.trim="lv_ed_cp_telno_station.value"
                      :disabled="lv_ed_cp_telno_station.disabled"
                      maxlength="4"
                      type="number"
                    />
                    <mo-text-field
                      ref="ed_cp_telno_seq"
                      :class="lv_ed_cp_telno_seq.error ? 'error' : ''"
                      v-model.trim="lv_ed_cp_telno_seq.value"
                      :disabled="lv_ed_cp_telno_seq.disabled"
                      maxlength="4"
                      type="number"
                    />
                    <mo-checkbox v-model="lv_chk_del_cp_telno.value" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span :class="lv_e_addr_p ? 'emphasis' : ''"> E-mail </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <div class="wrap-input row">
                      <mo-text-field class="input-email01 w137" :class="lv_ed_email_addr.error ? 'error' : ''" v-model.trim="lv_ed_email_addr.value" :disabled="lv_ed_email_addr.disabled" />
                      <span class="ico-between-email"> @ </span>
                      <mo-text-field class="input-email02 w137" :class="lv_ed_domain_name.error ? 'error' : ''" v-model.trim="lv_ed_domain_name.value" :disabled="lv_ed_domain_name.disabled" />
                      <mo-checkbox v-model="lv_chk_del_email_addr.value" class="row"> 삭제 </mo-checkbox>
                    </div>
                  </div>
                </td>
              </tr>

              <tr v-if="lv_sta_dnc">
                <th>
                  <span> 두낫콜정보 </span>
                </th>
                <td colspan="3">
                  <div class="row">
                    <div class="">
                      {{ lv_ed_dnc_2 }}
                    </div>
                    <div class="ml-5">
                      <p v-if="lv_ed_dnc_dtl_1" class="list-tri">{{ lv_ed_dnc_dtl_1 }}</p>
                      <p v-if="lv_ed_dnc_dtl_2" class="list-tri">{{ lv_ed_dnc_dtl_2 }}</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" :disabled="lv_btn_update" @click="fn_Update()"> 수정 </mo-button>
        </div>
      </ur-box-container>
    </main>

    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>

    <!-- popup100 -->
    <msp-fs-100p ref="popup100" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup100Obj"></msp-fs-100p>

    <!-- popup106 -->
    <msp-fs-106p ref="popup106" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup106Obj"></msp-fs-106p>

    <!-- popup303 -->
    <msp-fs-303p ref="popup303" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup303Obj"></msp-fs-303p>

    <!-- popup308 -->
    <msp-fs-308p ref="popup308" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup308Obj"></msp-fs-308p>

    <!-- popup313 -->
    <msp-fs-313p ref="popup313" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup313Obj"></msp-fs-313p>

    <!-- popup324 -->
    <msp-fs-324p ref="popup324" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup324Obj"></msp-fs-324p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'
import FSFatcaUtil from '~/src/ui/fs/comm/FSFatcaUtil'
import FSFormUtil from '~/src/ui/fs/comm/FSFormUtil'

import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)

import MSPFS100P from '~/src/ui/fs/MSPFS100P' // 우편번호 (공통)
import MSPFS106P from '~/src/ui/fs/MSPFS106P' // 마케팅 동의여부 팝업
import MSPFS303P from '~/src/ui/fs/MSPFS303P' // FATCA개인 팝업
import MSPFS308P from '~/src/ui/fs/MSPFS308P' // 고객정보 팝업
import MSPFS313P from '~/src/ui/fs/MSPFS313P' // 업무대리인 조회 팝업
import MSPFS324P from '~/src/ui/fs/MSPFS324P' // 금감원개인정보노출 팝업

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPFS108M',
  screenId: 'MSPFS108M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-100p': MSPFS100P,
    'msp-fs-106p': MSPFS106P,
    'msp-fs-303p': MSPFS303P,
    'msp-fs-308p': MSPFS308P,
    'msp-fs-313p': MSPFS313P,
    'msp-fs-324p': MSPFS324P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode()
  },
  mounted() {
    this.fn_Init()
    this.$bizUtil.insSrnLog('MSPFS108M')
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 공통 객체
      lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      lv_mkt_chk: '',
      pHeaderObj: {
        title: '고객정보정정',
        step: ''
      },
      pAlertPopupObj: {},
      lv_commCode: {},
      lv_org_code221: [],

      // 팝업 객체
      pPopup100Obj: {},
      pPopup106Obj: {},
      pPopup303Obj: {},
      pPopup308Obj: {},
      pPopup313Obj: {},
      pPopup324Obj: {},

      // 검색조건
      lv_ed_cust_no: {}, // 고객번호
      lv_ed_cust_nm: {}, // 고객명
      lv_rdo_agnt_chk_yn: {}, // 대리인여부
      lv_corp_gb: 'N', // 개인/법인여부

      // 조회결과
      lv_sy100500_data: {},
      lv_sy001300_data: {},
      lv_dnc_info: {},
      lv_cu150000_info: {},

      // 기본정보
      lv_cust_info_base: {}, // 고객정보기준표기

      lv_ed_rname_no: {}, // 실명번호(주민번호)
      lv_ed_rname_no1: {}, // 실명번호1
      lv_chk_cdi_sync_yn: {}, // 동기화
      lv_ed_cdi_sync_yn: {}, // CDI동기화여부
      lv_img_info_text: false, // 정보노출고객버튼

      lv_cob_rname_gb: {}, // 실명번호종류
      lv_before_cob_rname_gb: '',

      lv_cust_nm: {}, // 고객명
      lv_cust_enm: {}, // 고객영문명

      lv_cob_ctzn_natn_cd: {}, // 국적
      lv_cob_resi_natn_cd: {}, // 거주국

      lv_cob_inc_earner_gb: {}, // 소득자구분
      lv_rad_resi_yn: {}, // 거주자여부

      lv_cob_life_gb: {}, // 우대구분
      lv_ed_addrmrk_nm: {}, // 부기명/부서명

      lv_cal_birth_dt: {}, // 생일
      lv_rad_birth_solun_gb: {}, // 생일구분
      lv_rad_mail_recv_gb: {}, // 우편물통보처
      lv_rad_fund_recv_gb: {}, // 펀드잔고통보처
      lv_cob_tax_gb: {}, // 과세구분

      lv_rad_crd_int_yn1: {}, // 계약체결
      lv_rad_crd_int_yn2: {}, // 마케팅
      lv_bf_crd_int_yn2: {},

      lv_ed_mkt_cust_deal_yn: {},
      lv_ed_mkt_new_gb: {},

      lv_mask_crd_endymd: {}, // 종료일자
      lv_ed_crd_endymd: {},
      lv_ed_crd_strymd: {},
      lv_ed_crd_term: {},

      lv_cob_mkt_cnvs_mth_tel_yn: {}, // 연락방식-전화
      lv_cob_mkt_cnvs_mth_sms_yn: {}, // 연락방식-문자
      lv_cob_mkt_cnvs_mth_email_yn: {}, // 연락방식-이메일
      lv_cob_mkt_cnvs_mth_pst_yn: {}, // 연락방식-우편

      // 두낫콜정보
      lv_sta_dnc: false, // 보이기/숨김
      lv_ed_dnc_2: '',
      lv_ed_dnc_dtl_1: '',
      lv_ed_dnc_dtl_2: '',

      // 연락처정보
      // 자택주소
      lv_ed_h_addr_zipcode_new: {}, // 우편번호
      lv_chk_h_zipcode_no: '', // 우편번호체크
      lv_ed_h_addr_zipcode_text: '', // 신/구주소TEXT
      lv_chk_del_h_addr: {}, // 자택주소삭제
      lv_ed_h_addr_stdzt_yn: {}, // 주소표준화여부
      lv_h_addr_p: false, // 필수표시

      lv_ed_h_addr_zipcode1: '',
      lv_ed_h_addr_zipcode2: '',
      lv_ed_h_addr_zipcode3: '',

      lv_ed_h_telno_area: {}, // 자택전화번호1
      lv_ed_h_telno_station: {}, // 자택전화번호2
      lv_ed_h_telno_seq: {}, // 자택전화번호3
      lv_chk_del_h_telno: {}, // 자택전화번호삭제

      lv_ed_h_new_addr_gb: '', // 주소구분?

      lv_ed_h_zip_addr: {},
      lv_ed_h_bldg_no: {},
      lv_ed_h_remn_addr: {},
      lv_ed_h_adtn_addr: {},

      // 직장주소
      lv_ed_o_name: {}, // 직장명

      lv_ed_o_addr_zipcode_new: {}, // 우편번호
      lv_ed_o_addr_zipcode_text: '', // 신/구주소TEXT
      lv_chk_o_zipcode_no: '', // 우편번호체크
      lv_chk_del_o_addr: {}, // 직장주소삭제
      lv_ed_o_addr_stdzt_yn: {}, // 주소표준화여부
      lv_o_addr_p: false, // 필수표시

      lv_ed_o_addr_zipcode1: '',
      lv_ed_o_addr_zipcode2: '',
      lv_ed_o_addr_zipcode3: '',

      lv_ed_o_telno_area: {}, // 자택전화번호1
      lv_ed_o_telno_station: {}, // 자택전화번호2
      lv_ed_o_telno_seq: {}, // 자택전화번호3
      lv_chk_del_o_telno: {}, // 자택전화번호삭제

      lv_ed_o_new_addr_gb: '', // 주소구분?

      lv_ed_o_zip_addr: {},
      lv_ed_o_bldg_no: {},
      lv_ed_o_remn_addr: {},
      lv_ed_o_adtn_addr: {},

      lv_ed_faxno_area: {}, // FAX번호1
      lv_ed_faxno_station: {}, // FAX번호2
      lv_ed_faxno_seq: {}, // FAX번호3
      lv_chk_del_faxno: {}, // FAX번호삭제

      lv_cob_cp_telno_comp: {}, // 휴대폰번호1
      lv_ed_cp_telno_station: {}, // 휴대폰번호2
      lv_ed_cp_telno_seq: {}, // 휴대폰번호3
      lv_chk_del_cp_telno: {}, // 휴대폰번호삭제
      lv_p_addr_p: false, // 휴대폰번호필수표시

      lv_ed_email_addr: {}, // EMAIL1
      lv_ed_domain_name: {}, // EMAIL2
      lv_chk_del_email_addr: {}, // EMAIL삭제
      lv_e_addr_p: false, // EMAIL필수표시

      // AS-IS 전역변수 컨버팅
      lv_cdi_crd_term: '',

      // 버튼
      lv_btn_update: true,

      // DATASET
      lv_cdi_bp_data: [], // CDI BP정보
      lv_submit_data: {}, // 개인정보 수정 DATASET

      /***********************************************************************************
       * F10570167 : 고객정보조회LIST
       * F10570281 : 고객정보조회(두낫콜)
       * F10570134 : 두낫콜정보조회
       * F10570293 : 고객정보 조회
       * F10570294 : 고객정보 수정
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // 대리인여부
    itemsAgent() {
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    },

    // 거주자여부
    residentPerson() {
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    },

    // 신용정보 동의여부 > 마케팅 동의
    marketingAgree() {
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    },

    // 신용정보 동의여부 > 계약체결
    creditAgree() {
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    },

    // 휴대폰번호1
    phoneNum() {
      let rtn = []
      rtn.push({ value: '010', text: '010' })
      rtn.push({ value: '011', text: '011' })
      rtn.push({ value: '016', text: '016' })
      rtn.push({ value: '017', text: '017' })
      rtn.push({ value: '018', text: '018' })
      rtn.push({ value: '019', text: '019' })
      return rtn
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 대리인여부
    'lv_rdo_agnt_chk_yn.value': {
      handler(after, before) {
        if (after == 'Y') {
          // 대리인 만기체크
          this.fn_AgentCheck()
        }
      },
      deep: true
    },

    // 실명번호종류
    'lv_cob_rname_gb.value': {
      handler(after, before) {
        this.lv_before_cob_rname_gb = after
      },
      deep: true
    },

    // 자택주소삭제
    'lv_chk_del_h_addr.value': {
      handler(after, before) {
        if (after == true) {
          this.lv_ed_h_addr_zipcode1 = ''
          this.lv_ed_h_addr_zipcode2 = ''
          this.lv_ed_h_addr_zipcode3 = ''
          this.lv_ed_h_addr_zipcode_new.value = ''

          this.lv_chk_h_zipcode_no = ''

          this.lv_ed_h_zip_addr.value = ''
          this.lv_ed_h_bldg_no.value = ''
          this.lv_ed_h_remn_addr.value = ''
          this.lv_ed_h_adtn_addr.value = ''
        }
      },
      deep: true
    },

    // 자택전화번호삭제
    'lv_chk_del_h_telno.value': {
      handler(after, before) {
        if (after == true) {
          this.lv_ed_h_telno_area.value = ''
          this.lv_ed_h_telno_area.disabled = true

          this.lv_ed_h_telno_station.value = ''
          this.lv_ed_h_telno_station.disabled = true

          this.lv_ed_h_telno_seq.value = ''
          this.lv_ed_h_telno_seq.disabled = true
        } else {
          this.lv_ed_h_telno_area.disabled = false
          this.lv_ed_h_telno_station.disabled = false
          this.lv_ed_h_telno_seq.disabled = false
        }
      },
      deep: true
    },

    // 직장주소삭제
    'lv_chk_del_o_addr.value': {
      handler(after, before) {
        if (after == true) {
          this.lv_ed_o_addr_zipcode1 = ''
          this.lv_ed_o_addr_zipcode2 = ''
          this.lv_ed_o_addr_zipcode3 = ''
          this.lv_ed_o_addr_zipcode_new.value = ''

          this.lv_chk_o_zipcode_no = ''

          this.lv_ed_o_zip_addr.value = ''
          this.lv_ed_o_bldg_no.value = ''
          this.lv_ed_o_remn_addr.value = ''
          this.lv_ed_o_adtn_addr.value = ''
        }
      },
      deep: true
    },

    // 직장전화번호삭제
    'lv_chk_del_o_telno.value': {
      handler(after, before) {
        if (after == true) {
          this.lv_ed_o_telno_area.value = ''
          this.lv_ed_o_telno_area.disabled = true

          this.lv_ed_o_telno_station.value = ''
          this.lv_ed_o_telno_station.disabled = true

          this.lv_ed_o_telno_seq.value = ''
          this.lv_ed_o_telno_seq.disabled = true
        } else {
          this.lv_ed_o_telno_area.disabled = false
          this.lv_ed_o_telno_station.disabled = false
          this.lv_ed_o_telno_seq.disabled = false
        }
      },
      deep: true
    },

    // FAX번호삭제
    'lv_chk_del_faxno.value': {
      handler(after, before) {
        if (after == true) {
          this.lv_ed_faxno_area.value = ''
          this.lv_ed_faxno_area.disabled = true

          this.lv_ed_faxno_station.value = ''
          this.lv_ed_faxno_station.disabled = true

          this.lv_ed_faxno_seq.value = ''
          this.lv_ed_faxno_seq.disabled = true
        } else {
          this.lv_ed_faxno_area.disabled = false
          this.lv_ed_faxno_station.disabled = false
          this.lv_ed_faxno_seq.disabled = false
        }
      },
      deep: true
    },

    // 휴대폰번호삭제
    'lv_chk_del_cp_telno.value': {
      handler(after, before) {
        if (after == true) {
          this.lv_cob_cp_telno_comp.value = '010'
          this.lv_cob_cp_telno_comp.disabled = true

          this.lv_ed_cp_telno_station.value = ''
          this.lv_ed_cp_telno_station.disabled = true

          this.lv_ed_cp_telno_seq.value = ''
          this.lv_ed_cp_telno_seq.disabled = true
        } else {
          this.lv_cob_cp_telno_comp.disabled = false
          this.lv_ed_cp_telno_station.disabled = false
          this.lv_ed_cp_telno_seq.disabled = false
        }
      },
      deep: true
    },

    // EMAIL삭제
    'lv_chk_del_email_addr.value': {
      handler(after, before) {
        if (after == true) {
          this.lv_ed_email_addr.value = ''
          this.lv_ed_email_addr.disabled = true

          this.lv_ed_domain_name.value = ''
          this.lv_ed_domain_name.disabled = true
        } else {
          this.lv_ed_email_addr.disabled = false
          this.lv_ed_domain_name.disabled = false
        }
      },
      deep: true
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')

      let params = [
        { cId: '202', dayChkYn: 'N' }, // 과세구분
        { cId: '205', dayChkYn: 'N' }, // 음력앙력구분
        { cId: '206', dayChkYn: 'N' }, // 소득자구분
        { cId: '210', dayChkYn: 'N' }, // 생계형구분
        { cId: '211', dayChkYn: 'N' }, // 실명번호종류
        { cId: '221', dayChkYn: 'N' }, // 우편물통보처
        { cId: '223', dayChkYn: 'N' }, // 펀드잔고통보처
        { cId: '981', dayChkYn: 'N' } // 국적
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult
      this.lv_org_code221 = this.lv_commCode.code221

      // 실명번호종류 코드 재설정
      let t_codeList = []
      pResult.code211.forEach((item) => {
        if (item.value != '02') {
          t_codeList.push(item)
        }
      })
      this.lv_commCode.code211 = t_codeList

      // 소득자구분 코드 재설정
      let t_codeList206 = []
      pResult.code206.forEach((item) => {
        if (item.value != '112') {
          t_codeList206.push(item)
        }
      })
      this.lv_commCode.code206 = t_codeList206
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      console.log('[MSPFS108M] fn_Init...')

      // 검색조건
      this.lv_ed_cust_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_cust_no') // 고객번호
      this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1) // 고객명
      this.lv_rdo_agnt_chk_yn = FSCommUtil.gfn_dataSet(1) // 대리인여부
      this.lv_corp_gb = 'N' // 개인/법인여부
      this.lv_mkt_chk = ''

      // 조회결과
      this.lv_sy100500_data = {}
      this.lv_sy001300_data = {}
      this.lv_dnc_info = {}
      this.lv_cu150000_info = {}

      // 기본정보
      this.lv_cust_info_base = FSCommUtil.gfn_dataSet(0, '', false, '', '', false) // 고객정보기준표기

      this.lv_ed_rname_no = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_rname_no') // 실명번호(주민번호)
      this.lv_ed_rname_no1 = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_rname_no1') // 실명번호1
      this.lv_chk_cdi_sync_yn = FSCommUtil.gfn_dataSet(1, '', false) // 동기화
      this.lv_ed_cdi_sync_yn = FSCommUtil.gfn_dataSet(1, '', false) // CDI동기화여부
      this.lv_img_info_text = false // 정보노출고객버튼

      this.lv_cob_rname_gb = FSCommUtil.gfn_dataSet(0, '01', false) // 실명번호종류
      this.lv_before_cob_rname_gb = ''

      this.lv_cust_nm = FSCommUtil.gfn_dataSet(1, '', true) // 고객명
      this.lv_cust_enm = FSCommUtil.gfn_dataSet(1, '', false) // 고객영문명

      this.lv_cob_ctzn_natn_cd = FSCommUtil.gfn_dataSet(0, 'KR', false) // 국적
      this.lv_cob_resi_natn_cd = FSCommUtil.gfn_dataSet(0, 'KR', false) // 거주국

      this.lv_cob_inc_earner_gb = FSCommUtil.gfn_dataSet(0, '111', false) // 소득자구분
      this.lv_rad_resi_yn = FSCommUtil.gfn_dataSet(0, '', false) // 거주자여부
      this.lv_ed_res_nm = FSCommUtil.gfn_dataSet(0, '', false) // 대표자명

      this.lv_cob_life_gb = FSCommUtil.gfn_dataSet(0, '0', false) // 우대구분
      this.lv_ed_addrmrk_nm = FSCommUtil.gfn_dataSet(0, '', false) // 부기명/부서명

      this.lv_cal_birth_dt = FSCommUtil.gfn_dataSet(0, '', false) // 생일
      this.lv_rad_birth_solun_gb = FSCommUtil.gfn_dataSet(0, '01', false) // 생일구분
      this.lv_rad_mail_recv_gb = FSCommUtil.gfn_dataSet(0, '15', false) // 우편물통보처
      this.lv_rad_fund_recv_gb = FSCommUtil.gfn_dataSet(0, '', false) // 펀드잔고통보처
      this.lv_cob_tax_gb = FSCommUtil.gfn_dataSet(0, '', false) // 과세구분

      this.lv_rad_crd_int_yn1 = FSCommUtil.gfn_dataSet(0, 'N', false) // 계약체결
      this.lv_rad_crd_int_yn2 = FSCommUtil.gfn_dataSet(0, '', false) // 마케팅
      this.lv_bf_crd_int_yn2 = FSCommUtil.gfn_dataSet(0, '', false)

      this.lv_ed_mkt_cust_deal_yn = FSCommUtil.gfn_dataSet(0, '', false)
      this.lv_ed_mkt_new_gb = FSCommUtil.gfn_dataSet(0, '', false)

      this.lv_mask_crd_endymd = FSCommUtil.gfn_dataSet(0, '', true) // 종료일자
      this.lv_ed_crd_endymd = FSCommUtil.gfn_dataSet(0, '', true)
      this.lv_ed_crd_strymd = FSCommUtil.gfn_dataSet(0, '', true)
      this.lv_ed_crd_term = FSCommUtil.gfn_dataSet(0, '', true)

      this.lv_cob_mkt_cnvs_mth_tel_yn = FSCommUtil.gfn_dataSet(0, false, true) // 연락방식-전화
      this.lv_cob_mkt_cnvs_mth_sms_yn = FSCommUtil.gfn_dataSet(0, false, true) // 연락방식-문자
      this.lv_cob_mkt_cnvs_mth_email_yn = FSCommUtil.gfn_dataSet(0, false, true) // 연락방식-이메일
      this.lv_cob_mkt_cnvs_mth_pst_yn = FSCommUtil.gfn_dataSet(0, false, true) // 연락방식-우편

      // 두낫콜정보
      this.lv_sta_dnc = false // 보이기/숨김
      this.lv_ed_dnc_2 = ''
      this.lv_ed_dnc_dtl_1 = ''
      this.lv_ed_dnc_dtl_2 = ''

      // 연락처정보
      // 자택주소
      this.lv_ed_h_addr_zipcode_new = FSCommUtil.gfn_dataSet(0, '', true) // 우편번호
      this.lv_ed_h_addr_zipcode_text = '' // 신/구주소TEXT
      this.lv_chk_del_h_addr = FSCommUtil.gfn_dataSet(0, false, false) // 우편번호삭제
      this.lv_ed_h_addr_stdzt_yn = FSCommUtil.gfn_dataSet(0, '', true) // 주소표준화여부
      this.lv_h_addr_p = false // 필수표시

      this.lv_ed_h_addr_zipcode1 = ''
      this.lv_ed_h_addr_zipcode2 = ''
      this.lv_ed_h_addr_zipcode3 = ''

      this.lv_ed_h_telno_area = FSCommUtil.gfn_dataSet(0, '', false, '', 'ed_h_telno_area') // 자택전화번호1
      this.lv_ed_h_telno_station = FSCommUtil.gfn_dataSet(0, '', false, '', 'ed_h_telno_station') // 자택전화번호2
      this.lv_ed_h_telno_seq = FSCommUtil.gfn_dataSet(0, '', false, '', 'ed_h_telno_seq') // 자택전화번호3
      this.lv_chk_del_h_telno = FSCommUtil.gfn_dataSet(0, false, false) // 자택전화번호삭제

      this.lv_ed_h_new_addr_gb = '' // 주소구분?

      this.lv_ed_h_zip_addr = FSCommUtil.gfn_dataSet(0, '', true)
      this.lv_ed_h_bldg_no = FSCommUtil.gfn_dataSet(0, '', true)
      this.lv_ed_h_remn_addr = FSCommUtil.gfn_dataSet(0, '', true)
      this.lv_ed_h_adtn_addr = FSCommUtil.gfn_dataSet(0, '', true)

      // 직장주소
      this.lv_ed_o_name = FSCommUtil.gfn_dataSet(0, '', false, '', 'ed_o_name') // 직장명

      this.lv_ed_o_addr_zipcode_new = FSCommUtil.gfn_dataSet(0, '', true) // 우편번호
      this.lv_ed_o_addr_zipcode_text = '' // 신/구주소TEXT
      this.lv_chk_del_o_addr = FSCommUtil.gfn_dataSet(0, false, false) // 우편번호삭제
      this.lv_ed_o_addr_stdzt_yn = FSCommUtil.gfn_dataSet(0, '', true) // 주소표준화여부
      this.lv_o_addr_p = false // 필수표시

      this.lv_ed_o_addr_zipcode1 = ''
      this.lv_ed_o_addr_zipcode2 = ''
      this.lv_ed_o_addr_zipcode3 = ''

      this.lv_ed_o_telno_area = FSCommUtil.gfn_dataSet(0, '', false, '', 'ed_o_telno_area') // 직장전화번호1
      this.lv_ed_o_telno_station = FSCommUtil.gfn_dataSet(0, '', false, '', 'ed_o_telno_station') // 직장전화번호2
      this.lv_ed_o_telno_seq = FSCommUtil.gfn_dataSet(0, '', false, '', 'ed_o_telno_seq') // 직장전화번호3
      this.lv_chk_del_o_telno = FSCommUtil.gfn_dataSet(0, false, false) // 직장전화번호삭제

      this.lv_ed_o_new_addr_gb = '' // 주소구분?

      this.lv_ed_o_zip_addr = FSCommUtil.gfn_dataSet(0, '', true)
      this.lv_ed_o_bldg_no = FSCommUtil.gfn_dataSet(0, '', true)
      this.lv_ed_o_remn_addr = FSCommUtil.gfn_dataSet(0, '', true)
      this.lv_ed_o_adtn_addr = FSCommUtil.gfn_dataSet(0, '', true)

      this.lv_ed_faxno_area = FSCommUtil.gfn_dataSet(0, '', false) // FAX번호1
      this.lv_ed_faxno_station = FSCommUtil.gfn_dataSet(0, '', false) // FAX번호2
      this.lv_ed_faxno_seq = FSCommUtil.gfn_dataSet(0, '', false) // FAX번호3
      this.lv_chk_del_faxno = FSCommUtil.gfn_dataSet(0, false, false) // FAX번호삭제

      this.lv_cob_cp_telno_comp = FSCommUtil.gfn_dataSet(0, '010', false) // 휴대폰번호1
      this.lv_ed_cp_telno_station = FSCommUtil.gfn_dataSet(0, '', false, '', 'ed_cp_telno_station') // 휴대폰번호2
      this.lv_ed_cp_telno_seq = FSCommUtil.gfn_dataSet(0, '', false, '', 'ed_cp_telno_seq') // 휴대폰번호3
      this.lv_chk_del_cp_telno = FSCommUtil.gfn_dataSet(0, false, false) // 휴대폰번호삭제
      this.lv_p_addr_p = false // 휴대폰번호필수표시

      this.lv_ed_email_addr = FSCommUtil.gfn_dataSet(0, '', false) // EMAIL1
      this.lv_ed_domain_name = FSCommUtil.gfn_dataSet(0, '', false) // EMAIL2
      this.lv_chk_del_email_addr = FSCommUtil.gfn_dataSet(0, false, false) // EMAIL삭제
      this.lv_e_addr_p = false // EMAIL필수표시

      // 우편물통보처
      this.fn_set_code211()
      this.fn_rad_mail_recv_gb()

      // DATASET
      this.lv_cdi_bp_data = []
      this.lv_submit_data = {} // 개인정보 수정 DATASET
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/

    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type)
          0: 우편번호
          4: 마케팅
          MSPFS303P: FATCA개인          
          MSPFS308P: 고객정보
          MSPFS313P: 업무대리인 조회
          MSPFS324P: 금감원개인정보노출 조회
       ******************************************************************************/
    fn_OpenPopup(type, pData) {
      let t_popupObj = {}

      switch (type) {
        case 0:
          // 우편번호
          this.pPopup100Obj.btnFlag = pData
          this.$refs.popup100.fn_Open()
          break

        case 4:
          // 마케팅
          this.$refs.popup106.fn_Open()
          break

        case 'MSPFS303P':
          // FATCA개인
          this.pPopup303Obj = pData
          this.$refs.popup303.fn_Open()
          break

        case 'MSPFS308P':
          // 고객정보
          this.pPopup308Obj = {
            popupId: 'MSPFS308P'
          }
          this.$refs.popup308.fn_Open()
          break

        case 'MSPFS313P':
          // 업무대리인 조회 팝업
          this.pPopup313Obj = pData
          this.$refs.popup313.fn_Open()
          break

        case 'MSPFS324P':
          // 금감원개인정보노출 조회 팝업
          this.pPopup324Obj = pData
          this.$refs.popup324.fn_Open()
          break
      }
    },

    /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type)
          0: 우편번호
          MSPFS308P: 고객정보
       ******************************************************************************/
    fn_Popup_CallBack(type, pData) {
      switch (type) {
        case 0:
          console.log('우편번호 callback...')

          // 자택(HOME), 직장(JOB)
          if (pData.btnFlag == 'HOME') {
            //공통변수에 검색한 우편번호 값 추가
            this.lv_chk_h_zipcode_no = FSCommUtil.gfn_trim(pData['vZipcd_head']) + FSCommUtil.gfn_trim(pData['vZipcd_tail'])
            this.lv_ed_h_addr_zipcode_new.value = this.lv_chk_h_zipcode_no

            this.lv_ed_h_addr_zipcode1 = FSCommUtil.gfn_trim(pData['vZipcd_head'])
            this.lv_ed_h_addr_zipcode2 = FSCommUtil.gfn_trim(pData['vZipcd_tail'])
            this.lv_ed_h_addr_zipcode3 = pData['v_seq_no'] //주소시퀀스

            this.lv_ed_h_zip_addr.value = pData['vZipcd_ubmyundong'] //우편번호주소
            this.lv_ed_h_bldg_no.value = pData['v_bldg_no'] //건물번호
            this.lv_ed_h_remn_addr.value = pData['v_stand_addr'] //상세주소
            this.lv_ed_h_adtn_addr.value = pData['v_adtn_addr'] //부가주소

            this.lv_ed_h_addr_stdzt_yn.value = pData['v_addr_stdzt_yn'] //주소표준화여부
            this.lv_ed_h_new_addr_gb = '2' //주소구분
          } else if (pData.btnFlag == 'JOB') {
            //공통변수에 검색한 우편번호 값 추가
            this.lv_chk_o_zipcode_no = FSCommUtil.gfn_trim(pData['vZipcd_head']) + FSCommUtil.gfn_trim(pData['vZipcd_tail'])
            this.lv_ed_o_addr_zipcode_new.value = this.lv_chk_o_zipcode_no

            this.lv_ed_o_addr_zipcode1 = FSCommUtil.gfn_trim(pData['vZipcd_head'])
            this.lv_ed_o_addr_zipcode2 = FSCommUtil.gfn_trim(pData['vZipcd_tail'])
            this.lv_ed_o_addr_zipcode3 = pData['v_seq_no'] //주소시퀀스

            this.lv_ed_o_zip_addr.value = pData['vZipcd_ubmyundong'] //우편번호주소
            this.lv_ed_o_bldg_no.value = pData['v_bldg_no'] //건물번호
            this.lv_ed_o_remn_addr.value = pData['v_stand_addr'] //상세주소
            this.lv_ed_o_adtn_addr.value = pData['v_adtn_addr'] //부가주소

            this.lv_ed_o_addr_stdzt_yn.value = pData['v_addr_stdzt_yn'] //주소표준화여부
            this.lv_ed_o_new_addr_gb = '2' //주소구분
          }

          //주소 신구 표기
          this.fn_nwOldAddrScCd()
          break

        case 4:
          console.log('마케팅 동의여부 callback...')
          console.log(pData)

          if (pData.vNewYn == 'NEW') {
            this.lv_mkt_chk = 'Y'
            //마케팅권유방법 추가
            this.lv_ed_mkt_new_gb.value = pData.vNewYn
            this.lv_ed_mkt_cust_deal_yn.value = pData.deal_yn

            this.lv_cob_mkt_cnvs_mth_tel_yn.value = pData.tel_yn
            this.lv_cob_mkt_cnvs_mth_sms_yn.value = pData.sms_yn
            this.lv_cob_mkt_cnvs_mth_email_yn.value = pData.email_yn
            this.lv_cob_mkt_cnvs_mth_pst_yn.value = pData.pst_yn

            this.lv_mask_crd_endymd.value = pData.end_dt

            this.lv_ed_crd_endymd.value = pData.end_dt
            this.lv_ed_crd_strymd.value = FSCommUtil.gfn_strToday(1)
            this.lv_ed_crd_term.value = pData.avl_prd
          } else {
            this.lv_mkt_chk = ''
            this.lv_rad_crd_int_yn2.value = 'N'
            this.lv_mask_crd_endymd.value = ''

            this.lv_cob_mkt_cnvs_mth_tel_yn.value = false
            this.lv_cob_mkt_cnvs_mth_sms_yn.value = false
            this.lv_cob_mkt_cnvs_mth_email_yn.value = false
            this.lv_cob_mkt_cnvs_mth_pst_yn.value = false
          }
          break

        case 'MSPFS303P':
          console.log('FATCA개인 callback...')

          // FATCA개인 정상 진행 완료시 호출
          this.fn_Update('Y')
          break

        case 'MSPFS308P':
          console.log('고객정보 callback...')

          // 고객번호 매핑 후 고객정보조회
          this.lv_ed_cust_no.value = pData
          this.fn_SearchValid({ key: 'v' })
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_nwOldAddrScCd
     * 설명       : 주소 신구 표기
     ******************************************************************************/

    fn_nwOldAddrScCd() {
      console.log('fn_nwOldAddrScCd...')

      if (FSCommUtil.gfn_length(this.lv_ed_h_addr_zipcode3.value) == 3) {
        if (this.lv_ed_h_addr_zipcode3.value >= 600) {
          this.lv_ed_h_addr_zipcode_text = '(신)'
        } else {
          this.lv_ed_h_addr_zipcode_text = '(구)'
        }
      }

      if (FSCommUtil.gfn_length(this.lv_ed_o_addr_zipcode3.value) == 3) {
        if (this.lv_ed_o_addr_zipcode3.value >= 600) {
          this.lv_ed_o_addr_zipcode_text = '(신)'
        } else {
          this.lv_ed_o_addr_zipcode_text = '(구)'
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_AgentCheck
     * 설명       : 대리인 만기체크
     ******************************************************************************/
    fn_AgentCheck() {
      console.log('fn_AgentCheck...')

      // 초기화
      this.lv_ed_cust_no.error = false

      if (FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value)) {
        FSCommUtil.gfn_validate(this, '고객번호가 입력되지 않았습니다.')
        this.lv_ed_cust_no.error = true
        this.$refs['ed_cust_no'].focus()
        this.lv_rdo_agnt_chk_yn.value = ''
        return
      } else if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7) {
        FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
        this.lv_ed_cust_no.error = true
        this.$refs['ed_cust_no'].focus()
        this.lv_rdo_agnt_chk_yn.value = ''
        return
      }

      let t_params = {
        // PO 호출 조회조건
        bfcer_cust_no: this.lv_ed_cust_no.value,
        tr_code: 'CU150000',

        // 추가정보
        menu_id: 'MSPFS108M',
        btn_id: 'lv_rdo_agnt_chk_yn'
      }
      FSInfoUtil.agentCheck(this, t_params, this.fn_AlertPopup)
    },

    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_SearchValid(event) {
      console.log('fn_SearchValid.....')

      if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) == 7) {
        this.lv_ed_cust_no.error = false

        if (!FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value)) {
          FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
          this.lv_ed_cust_no.error = true
          this.$refs['ed_cust_no'].focus()
          return
        } else {
          if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile) {
            // 고객정보조회
            this.fn_CustInfo()
          }
        }
      } else if (event.key == 'Delete' || event.key == 'Backspace') {
        // 고객명 초기화
        this.lv_ed_cust_nm.value = ''
      }
    },

    /******************************************************************************
     * Function명 : fn_CustInfo, fn_SY1005_call_back, fn_call_back_info, fn_search_callback2
     * 설명       : 고객번호로 검색조건 조회 후 두낫콜 조회
     ******************************************************************************/
    fn_CustInfo() {
      console.log('fn_CustInfo.....')

      let t_data = { bfcer_cust_no: this.lv_ed_cust_no.value }
      FSInfoUtil.commInfo(this, 1, t_data, this.fn_SY1005_call_back)
    },

    fn_SY1005_call_back(pResultData) {
      console.log('fn_SY1005_call_back.....')

      let t_popupObj = {}
      let t_data = pResultData.data
      this.lv_sy100500_data = t_data.sy100500_O_00VO[0]
      console.log(t_data)

      if (t_data.error_msg == '809900' && FSCommUtil.gfn_length(t_data.sy100500_O_00VO) > 0) {
        this.lv_ed_cust_nm.value = this.lv_sy100500_data.fnd_cust_nm // 고객명
        this.lv_cust_nm.value = this.lv_sy100500_data.fnd_cust_nm // 고객명(기본정보)
        this.lv_ed_cdi_sync_yn.value = this.lv_sy100500_data.cust_info_sync_yn // CDI동기화여부

        if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_sy100500_data.fnd_cust_nm))) {
          FSCommUtil.gfn_validate(this, '해당 고객은 당사 거래 종료로 인한 개인정보 접근제한 고객입니다.')
          // return
        }

        // 개인/법인 체크
        this.lv_corp_gb = this.lv_sy100500_data.corp_yn

        // 우편물통보처 코드 재설정
        this.fn_set_code211()
      } else {
        // 고객번호 초기화
        this.lv_ed_cust_no.value = ''
      }

      // 고객정보조회(두낫콜)
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570281_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: this.lv_ed_cust_no.value // 수익증권고객번호
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_info)
    },

    fn_call_back_info(pResultData) {
      console.log('fn_call_back_info.....')

      let t_popupObj = {}
      let t_data = pResultData.data
      console.log(t_data)

      // 법인이거나 실명번호종류가 주민등록번호가 아닌경우
      if (this.lv_corp_gb == 'Y' || this.lv_sy100500_data.bfcer_rlnm_no_knd_cd != '01') {
        this.lv_sy001300_data = t_data.tfoc02000_O_VO[0]
      } else {
        this.lv_sy001300_data = t_data.eaf1MDI0083_O_OOVO[0]
      }

      if (t_data.error_msg == '809900') {
        // 고객정보조회(두낫콜)
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570134_S'
        this.eaiCommObj.params = {
          bfcer_cust_no: this.lv_ed_cust_no.value // 수익증권고객번호
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_search_callback2)
      } else {
        this.fn_dnc_init(false)

        t_popupObj.content = FSMessageUtil.commMessage([])[t_data.error_msg]
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    // 두낫콜
    fn_search_callback2(pResultData) {
      console.log('fn_search_callback2.....')

      let t_popupObj = {}
      let t_data = pResultData.data
      this.lv_dnc_info = t_data
      console.log(t_data)

      if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.error_msg))) {
        // 금감원정보노출사건번호 체크
        if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_sy001300_data.fssInfoExpsrEvntNo))) {
          //정보노출고객 버튼 숨김
          this.lv_img_info_text = false
        } else {
          //정보노출고객 버튼 보이기
          this.lv_img_info_text = true
        }

        // 두낫콜 초기화
        if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.dnc_call_end_dt))) {
          this.fn_dnc_init(false)
          return
        }

        // 두낫콜 문구 세팅
        let dnc_text = t_data.bman_celph_dstno_encr + '-' + t_data.bman_celph_tlnum_no_encr + '-' + t_data.bman_celph_bkno_sno_encr

        if (FSCommUtil.gfn_length(FSCommUtil.gfn_trim(t_data.dnc_call_gb)) > 0) {
          dnc_text += ' 전화'
          // this.fn_dnc_init(true)
        }

        if (FSCommUtil.gfn_length(FSCommUtil.gfn_trim(t_data.dnc_sms_gb)) > 0) {
          dnc_text += ' SMS'
          // this.fn_dnc_init(true)
        }

        if (FSCommUtil.gfn_length(FSCommUtil.gfn_trim(t_data.dnc_sms_gb)) <= 0 && FSCommUtil.gfn_length(FSCommUtil.gfn_trim(t_data.dnc_sms_gb)) <= 0) {
          this.fn_dnc_init(false)
          return
        }

        this.lv_ed_dnc_2 = dnc_text + ' 통한 마케팅 연락 거부'

        // 두낫콜 활성화
        this.fn_dnc_init(true)

        // 두낫콜 메세지 세팅
        this.fn_ed_dnc_dtl()
      } else {
        t_popupObj.content = FSMessageUtil.commMessage([])[t_data.error_msg]
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_dnc_init
     * 설명       : 두낫콜 초기화
     ******************************************************************************/
    fn_dnc_init(flag) {
      console.log('fn_dnc_init...')

      this.lv_sta_dnc = flag
    },

    /******************************************************************************
     * Function명 : fn_ed_dnc_dtl
     * 설명       : 두낫콜 메세지 세팅
     ******************************************************************************/
    fn_ed_dnc_dtl(flag) {
      console.log('fn_ed_dnc_dtl...')

      let str1 = ''
      let str2 = ''
      let dnc_cnt = 0

      if (this.lv_dnc_info.dnc_call_gb == '1') {
        str1 += '전 화 : '
        str1 += '신청일자 ' + this.lv_dnc_info.dnc_call_req_dt + ' / '
        str1 += '적재일자 ' + this.lv_dnc_info.dnc_call_insert_dt + ' / '
        str1 += '종료일자 ' + this.lv_dnc_info.dnc_call_end_dt
        dnc_cnt++
      }

      if ((this.lv_dnc_info.dnc_sms_gb = '2')) {
        if (dnc_cnt == 0) {
          str1 += 'SMS : '
          str1 += '신청일자 ' + this.lv_dnc_info.dnc_sms_req_dt + ' / '
          str1 += '적재일자 ' + this.lv_dnc_info.dnc_sms_insert_dt + ' / '
          str1 += '종료일자 ' + this.lv_dnc_info.dnc_sms_end_dt
        } else {
          str2 += 'SMS : '
          str2 += '신청일자 ' + this.lv_dnc_info.dnc_sms_req_dt + ' / '
          str2 += '적재일자 ' + this.lv_dnc_info.dnc_sms_insert_dt + ' / '
          str2 += '종료일자 ' + this.lv_dnc_info.dnc_sms_end_dt
        }
      }

      this.lv_ed_dnc_dtl_1 = str1
      this.lv_ed_dnc_dtl_2 = str2
    },

    /******************************************************************************
     * Function명 : fn_visible_check
     * 설명       : 조회결과 활성화/비활성화
     ******************************************************************************/
    fn_visible_check(flag) {
      console.log('fn_visible_check...')

      this.lv_ed_rname_no1.visibled = flag
      this.lv_ed_rname_no.visibled = !flag
    },

    /******************************************************************************
     * Function명 : fn_cust_info_base
     * 설명       : 고객정보기준표기
     ******************************************************************************/
    fn_cust_info_base(vBase) {
      console.log('fn_cust_info_base...')

      if (vBase == 'P') {
        this.lv_cust_info_base.value = '기준정보: 보험ERP'
        this.lv_cust_info_base.visibled = true
      } else if (vBase == 'S') {
        this.lv_cust_info_base.value = '기준정보: 수익증권'
        this.lv_cust_info_base.visibled = true
      } else {
        this.lv_cust_info_base.visibled = false
      }
    },

    /******************************************************************************
     * Function명 : fn_set_code211
     * 설명       : 우편물통보처 코드 재설정
     ******************************************************************************/
    fn_set_code211() {
      console.log('fn_set_code211...')

      let t_codeList221 = []

      if (this.lv_corp_gb == 'Y') {
        this.lv_org_code221.forEach((item) => {
          if (item.value != '11' && item.value != '15') {
            t_codeList221.push(item)
          }
        })
      } else {
        this.lv_org_code221.forEach((item) => {
          if (item.value == '14') {
            t_codeList221.push(item)
          }
          if (item.value == '15') {
            t_codeList221.unshift(item)
          }
        })
        this.lv_org_code221.forEach((item) => {
          if (item.value != '14' && item.value != '15') {
            t_codeList221.push(item)
          }
        })
      }
      this.lv_commCode.code221 = t_codeList221
    },

    /******************************************************************************
     * Function명 : fn_cob_life_gb
     * 설명       : 우대구분 변경처리
     ******************************************************************************/
    fn_cob_life_gb() {
      console.log('fn_cob_life_gb...')

      if (this.lv_cob_life_gb.value != '0' && this.lv_rad_resi_yn.value != 'Y') {
        FSCommUtil.gfn_validate(this, '생계형구분은 거주자만 변경 가능합니다.')
        this.lv_cob_life_gb.value = '0'
        return false
      }
    },

    /******************************************************************************
     * Function명 : fn_CU1500_rname_chk
     * 설명       : 실명번호종류 변경처리
     ******************************************************************************/
    fn_CU1500_rname_chk() {
      console.log('fn_CU1500_rname_chk...')

      //주민등록번호
      if (this.lv_cob_rname_gb.value == '01') {
        this.lv_cob_inc_earner_gb.value = '111'
      }

      //재외국민등록번호
      else if (this.lv_cob_rname_gb.value == '03') {
        this.lv_cob_inc_earner_gb.value = '122'
      }

      //외국인등록번호
      else if (this.lv_cob_rname_gb.value == '04') {
        this.lv_cob_inc_earner_gb.value = '131'
      }

      //국내거소신고번호
      else if (this.lv_cob_rname_gb.value == '05') {
        this.lv_cob_inc_earner_gb.value = '141'
        this.lv_rad_resi_yn.value = 'Y'
      }

      //여권번호등
      else if (this.lv_cob_rname_gb.value == '06') {
        this.lv_cob_inc_earner_gb.value = '121'
      }

      //사업자등록번호
      else if (this.lv_cob_rname_gb.value == '07') {
        this.lv_cob_inc_earner_gb.value = '211'
      }

      //투자등록증고유번호
      else if (this.lv_cob_rname_gb.value == '08') {
        FSCommUtil.gfn_validate(this, '투자등록증고유번호는 수정불가합니다.')
        this.lv_cob_rname_gb.value = this.lv_before_cob_rname_gb
        return false
      }

      //고유번호
      else if (this.lv_cob_rname_gb.value == '09') {
        this.lv_cob_inc_earner_gb.value = '311'
      }
    },

    /******************************************************************************
     * Function명 : fn_CU1500_rname_chk2
     * 설명       : 소득자구분 변경처리
     ******************************************************************************/
    fn_CU1500_rname_chk2() {
      console.log('fn_CU1500_rname_chk2...')

      //주민등록번호
      if (this.lv_cob_inc_earner_gb.value == '111') {
        this.lv_cob_rname_gb.value = '01'
      }

      //여권번호등
      else if (this.lv_cob_inc_earner_gb.value == '121') {
        this.lv_cob_rname_gb.value = '06'
      }

      //재외국민등록번호
      else if (this.lv_cob_inc_earner_gb.value == '122') {
        this.lv_cob_rname_gb.value = '03'
      }

      //외국인등록번호
      else if (this.lv_cob_inc_earner_gb.value == '131') {
        this.lv_cob_rname_gb.value = '04'
      }

      //국내거소신고번호
      else if (this.lv_cob_inc_earner_gb.value == '141') {
        this.lv_cob_rname_gb.value = '05'
        this.lv_rad_resi_yn.value = 'Y'
      }

      //사업자등록번호
      else if (this.lv_cob_inc_earner_gb.value == '211') {
        this.lv_cob_rname_gb.value = '07'
      }

      //고유번호
      else if (this.lv_cob_inc_earner_gb.value == '311') {
        this.lv_cob_rname_gb.value = '09'
      }

      //투자등록증고유번호
      else if (this.lv_cob_inc_earner_gb.value == '331' || this.lv_cob_inc_earner_gb.value == '411') {
        this.lv_cob_rname_gb.value = ''
      }
    },

    /******************************************************************************
     * Function명 : fn_img_info_text_onclick
     * 설명       : 정보노출고객 버튼
     ******************************************************************************/
    fn_img_info_text_onclick() {
      console.log('fn_img_info_text_onclick...')

      // 금감원정보노출사건번호 체크
      if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_sy001300_data.fssInfoExpsrEvntNo))) {
        // do nothing
      } else {
        // 실명번호(custRrn 값) 뒷자리 마스킹 처리시 정상 수정 안될수 있음
        let t_obj = {
          event_no: this.lv_sy001300_data.fssInfoExpsrEvntNo,
          event_cust_nm: this.lv_sy001300_data.custNm,
          event_reg_no: FSCommUtil.gfn_substr(this.lv_sy001300_data.custRrn, 0, 6) + '-' + FSCommUtil.gfn_substr(this.lv_sy001300_data.custRrn, 6, 1) + '******',
          tel_no: this.lv_sy001300_data.telno,
          hp_no: this.lv_sy001300_data.celno,
          sago_cd: this.lv_sy001300_data.fssInfoExpsrAcdtCd,
          sago_txt: this.lv_sy001300_data.fssInfoExpsrAcdtNm,
          apnd_cmpy: this.lv_sy001300_data.fssInfoExpsrRegCoNm,
          apnd_ymd: this.lv_sy001300_data.regDtm
        }

        this.fn_OpenPopup('MSPFS324P', t_obj)
      }
    },

    /***********************************************************************************
     * Function명 : fn_rad_mail_recv_gb
     * 설명       : 우편물통보처 변경 처리
     ***********************************************************************************/
    fn_rad_mail_recv_gb() {
      console.log('fn_rad_mail_recv_gb...')

      // 자택
      if (this.lv_rad_mail_recv_gb.value == '11') {
        this.lv_h_addr_p = true
        this.lv_o_addr_p = false
      }

      // 직장
      else if (this.lv_rad_mail_recv_gb.value == '12') {
        this.lv_h_addr_p = false
        this.lv_o_addr_p = true
      }

      // 통장 or 불원
      else {
        this.lv_h_addr_p = false
        this.lv_o_addr_p = false
      }

      this.lv_p_addr_p = false

      // EMAIL
      if (this.lv_rad_mail_recv_gb.value == '14' || this.lv_rad_fund_recv_gb.value == '14') {
        this.lv_e_addr_p = true
      } else {
        this.lv_e_addr_p = false
      }

      // 스마트
      if (this.lv_rad_mail_recv_gb.value == '15') {
        this.lv_e_addr_p = true
        this.lv_p_addr_p = true
      }
    },

    /***********************************************************************************
     * Function명 : fn_rad_fund_recv_gb
     * 설명       : 펀드잔고통보처 변경 처리
     ***********************************************************************************/
    fn_rad_fund_recv_gb() {
      console.log('fn_rad_fund_recv_gb...')

      // 사절
      if (this.lv_rad_fund_recv_gb.value == '19') {
        this.lv_e_addr_p = false
      }

      // E-mail
      if (this.lv_rad_mail_recv_gb.value == '14' || this.lv_rad_fund_recv_gb.value == '14') {
        this.lv_e_addr_p = true
      } else {
        this.lv_e_addr_p = false
      }
    },

    /***********************************************************************************
     * Function명 : fn_rad_crd_int_yn1
     * 설명       : 계약체결 변경 처리
     ***********************************************************************************/
    fn_rad_crd_int_yn1() {
      console.log('fn_rad_crd_int_yn1...')

      if (this.lv_rad_crd_int_yn1.value == 'N') {
        FSCommUtil.gfn_validate(this, '신용정보동의 I(23조) 는 동의거부로 변경하실 수 없습니다.')
        this.lv_rad_crd_int_yn1.value = 'Y'
      }
    },

    /***********************************************************************************
     * Function명 : fn_rad_crd_int_yn2
     * 설명       : 마케팅 변경 처리
     ***********************************************************************************/

    fn_rad_crd_int_yn2() {
      console.log('fn_rad_crd_int_yn2...')

      // 공백값에서 '아니오'를 선택해도 경고창 뜨게끔 조건 추가
      // if( (!FSCommUtil.gfn_isNull(this.lv_bf_crd_int_yn2.value) || this.lv_rad_crd_int_yn2.value == 'N') && this.lv_chk_cdi_sync_yn.value == 1 ) {
      //   FSCommUtil.gfn_validate(this, '#1510 불가 - 보험ERP에서 변경바랍니다.')
      //   this.lv_rad_crd_int_yn2.value = 'Y'

      // // 조회해온 마케팅동의여부 데이터가 공백인 경우에도 팝업이 표시되도록 조건 추가
      // } else if( FSCommUtil.gfn_isNull(this.lv_bf_crd_int_yn2.value) && this.lv_rad_crd_int_yn2.value == 'Y' ) {

      //   // 마케팅 팝업
      //   this.fn_OpenPopup(4)
      // }

      if (FSCommUtil.gfn_isNull(this.lv_mask_crd_endymd.value.replaceAll('-', '')) && FSCommUtil.gfn_isNull(this.lv_mkt_chk) && this.lv_rad_crd_int_yn2.value == 'N') {
      } else if (!FSCommUtil.gfn_isNull(this.lv_mask_crd_endymd.value.replaceAll('-', '')) || (this.lv_mkt_chk == 'Y' && this.lv_chk_cdi_sync_yn.value == 1 && this.lv_rad_crd_int_yn2.value == 'N')) {
        FSCommUtil.gfn_validate(this, '#1510 불가 - 보험ERP에서 변경바랍니다.')
        this.lv_rad_crd_int_yn2.value = 'Y'
      } else if (FSCommUtil.gfn_isNull(this.lv_mkt_chk) && this.lv_rad_crd_int_yn2.value == 'Y') {
        // 마케팅 팝업
        this.fn_OpenPopup(4)
      }
    },

    /***********************************************************************************
     * Function명 : fn_searchList, fn_searchListResult, fn_cdi_cancel
     * 설명       : 고객정보 조회
     ***********************************************************************************/
    fn_searchList() {
      console.log('fn_searchList...')

      if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7) {
        FSCommUtil.gfn_validate(this, '고객번호를 입력하세요.')
        this.lv_ed_cust_no.error = true
        this.$refs['ed_cust_no'].focus()
        return
      }

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570293_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: this.lv_ed_cust_no.value // 수익증권고객번호
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_searchListResult)
    },

    fn_searchListResult(pResultData) {
      console.log('fn_searchListResult...')

      let t_popupObj = {}
      let t_data = pResultData.data
      console.log(t_data)

      if (t_data.error_msg == '809900') {
        // 조회결과 매핑
        this.lv_cu150000_info = t_data

        // 수정버튼 활성화
        this.lv_btn_update = false

        // 고객번호 비활성화
        this.lv_ed_cust_no.disabled = true

        // 우편물통보처 변경 처리
        this.fn_rad_mail_recv_gb()
        // ASR240200194 / 사랑On3.0_수익증권 시스템의 고객정보정정 화면 변경
        this.lv_chk_cdi_sync_yn.value = 1
        this.fn_cdi_select()

        // this.BF_crd_int_yn2.set_value(this.Ds_cu1500.getColumn(0, "crd_int_yn2"))

        // CDI동기화여부 체크
        /* if( this.lv_ed_cdi_sync_yn.value != 'Y' ) {

            // 법인여부 체크
            if( this.lv_corp_gb == 'N' ) {
              // 개인
              let lv_vm = this
              let t_popupObj = {
                content: "고객정보통합(CDI) 동의하십니까?",
                cancel: true,
                closeFunc: this.fn_cdi_cancel,
                confirm: true,
                confirmFunc: function() {
                  // CDI확인조회
                  lv_vm.lv_chk_cdi_sync_yn.value = 1
                  lv_vm.fn_cdi_select()
                },
              }
              this.fn_AlertPopup(0, t_popupObj)

            } else {
              //법인
              this.fn_cdi_cancel()
            }
            
          } else {

            // CDI확인조회
            this.lv_chk_cdi_sync_yn.value = 1
            this.fn_cdi_select()
          } */
      } else {
        t_popupObj.content = FSMessageUtil.commMessage([])[t_data.error_msg]
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    fn_cdi_cancel() {
      console.log('fn_cdi_cancel...')

      let t_popupObj = {
        confirm: false,
        content: this.lv_corp_gb == 'Y' ? '수익증권 정보를 조회합니다.' : '동의하지 않습니다. 수익증권 정보를 조회합니다.'
      }
      this.fn_AlertPopup(0, t_popupObj)

      // 고객정보 표시
      this.fn_cust_info_base('S')

      // 고객정보 매핑(수익증권)
      this.fn_info_mapping(this.lv_cu150000_info)

      // 마케팅 여부 체크하여 세팅
      if (this.lv_cu150000_info.crd_int_yn2 == 'Y') {
        if (!FSCommUtil.gfn_isNull(this.lv_cu150000_info.mkt_cnsnt_avl_end_ymd)) {
          this.lv_rad_crd_int_yn2.value = this.lv_cu150000_info.crd_int_yn2
          this.lv_rad_crd_int_yn2.disabled = true

          this.lv_mask_crd_endymd.value = FSCommUtil.gfn_dateReplace(1, this.lv_cu150000_info.mkt_cnsnt_avl_end_ymd)
          this.lv_mask_crd_endymd.visibled = true
        }
      }

      // 우편번호/주소구분 세팅
      this.fn_set_addr_gb()
    },

    /***********************************************************************************
     * Function명 : fn_info_mapping
     * 설명       : 고객정보 매핑(수익증권 또는 CDI)
     ***********************************************************************************/
    fn_info_mapping(pData, flag) {
      console.log('fn_info_mapping...')

      let t_data = pData
      console.log(t_data)

      // DATASET 매핑
      this.lv_submit_data = t_data

      // ========================== 기본정보 ==========================
      // 실명번호종류 주민등록번호/외국인등록번호/국내거소신고번호 일 경우
      if (t_data.bfcer_incmr_sc_cd == '111' || t_data.bfcer_incmr_sc_cd == '121' || t_data.bfcer_incmr_sc_cd == '131' || t_data.bfcer_incmr_sc_cd == '141') {
        let rlnm_cnf_no_encr1 = FSCommUtil.gfn_substr(t_data.rlnm_cnf_no_encr, 0, 6)
        let rlnm_cnf_no_encr2 = FSCommUtil.gfn_substr(t_data.rlnm_cnf_no_encr, 6, 1)
        this.lv_ed_rname_no.value = rlnm_cnf_no_encr1 + '-' + rlnm_cnf_no_encr2 + '******'
      }

      // 실명번호법인
      this.lv_ed_rname_no1.value = t_data.rlnm_cnf_no_encr

      // 실명번호종류
      this.lv_cob_rname_gb.value = t_data.bfcer_rlnm_no_knd_cd

      // 고객명/고객영문명
      this.lv_cust_nm.value = FSCommUtil.gfn_trim(t_data.fnd_cust_nm)
      this.lv_cust_enm.value = FSCommUtil.gfn_trim(t_data.fnd_cust_eng_nm)

      // 국적
      this.lv_cob_ctzn_natn_cd.value = FSCommUtil.gfn_trim(t_data.bfcer_naty_cntry_cd)

      // 거주국
      this.lv_cob_resi_natn_cd.value = FSCommUtil.gfn_trim(t_data.fnd_dmcl_cntry_cd)

      // 소득자구분
      this.lv_cob_inc_earner_gb.value = t_data.bfcer_incmr_sc_cd

      // 거주자여부
      this.lv_rad_resi_yn.value = t_data.dwlr_yn

      this.fn_CU1500_rname_chk()
      this.fn_CU1500_rname_chk2()

      // 대표자명
      this.lv_ed_res_nm.value = FSCommUtil.gfn_trim(t_data.bfcer_resnt_nm)

      // 우대구분
      this.lv_cob_life_gb.value = t_data.bfcer_lvht_sc_cd

      // 생일/생일구분
      this.lv_cal_birth_dt.value = FSCommUtil.gfn_dateReplace(1, t_data.cust_birth_mmdd)
      this.lv_rad_birth_solun_gb.value = t_data.bfcer_slrc_lnrc_sc_cd

      // 부기명/부서명
      this.lv_ed_addrmrk_nm.value = t_data.bkkp_nm

      // 우편물통보처
      this.lv_rad_mail_recv_gb.value = t_data.bfcer_mail_trad_cd

      // 펀드잔고통보처
      this.lv_rad_fund_recv_gb.value = t_data.bfcer_fnd_blnc_ntc_sc_cd

      // 우편물통보처/펀드잔고통보처에 따른 필수값 함수
      this.fn_rad_mail_recv_gb()

      // 과세구분
      this.lv_cob_tax_gb.value = t_data.bfcer_fnd_taxt_sc_cd

      // 계약체결
      this.lv_rad_crd_int_yn1.value = t_data.crd_int_yn1

      // 마케팅
      this.lv_rad_crd_int_yn2.value = t_data.crd_int_yn2
      this.lv_bf_crd_int_yn2.value = t_data.crd_int_yn2

      // 연락방식
      this.lv_cob_mkt_cnvs_mth_tel_yn.value = t_data.mkt_cnvs_mth_tel_yn == 'Y' ? true : false
      this.lv_cob_mkt_cnvs_mth_sms_yn.value = t_data.mkt_cnvs_mth_sms_yn == 'Y' ? true : false
      this.lv_cob_mkt_cnvs_mth_email_yn.value = t_data.mkt_cnvs_mth_email_yn == 'Y' ? true : false
      this.lv_cob_mkt_cnvs_mth_pst_yn.value = t_data.mkt_cnvs_mth_pst_yn == 'Y' ? true : false

      // CDI 결합조회가 아닐경우
      if (flag != 'CDI') {
        // 종료일자
        this.lv_mask_crd_endymd.value = FSCommUtil.gfn_dateReplace(1, t_data.mkt_cnsnt_avl_end_ymd)
      }

      // this.lv_bf_crd_int_yn1.value      = t_data.busn_trust_cnsnt_yn

      this.lv_ed_mkt_new_gb.value = t_data.mkt_new_gb
      this.lv_ed_mkt_cust_deal_yn.value = t_data.dl_cust_yn

      this.lv_ed_crd_term.value = t_data.crd_term
      this.lv_ed_crd_strymd.value = t_data.crd_strymd
      this.lv_ed_crd_endymd.value = t_data.crd_endymd

      // ========================== 연락처정보 ==========================
      // 자택주소
      this.lv_ed_h_addr_zipcode1 = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.hom_addr_pstcd01)) ? '' : t_data.hom_addr_pstcd01
      this.lv_ed_h_addr_zipcode2 = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.hom_addr_pstcd02)) ? '' : t_data.hom_addr_pstcd02
      this.lv_ed_h_addr_zipcode3 = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.hom_addr_whl_pstcd)) ? '' : t_data.hom_addr_whl_pstcd
      this.lv_ed_h_addr_stdzt_yn.value = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.hom_addr_stdzt_yn)) ? '' : t_data.hom_addr_stdzt_yn

      this.lv_ed_h_zip_addr.value = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.cust_hom_pstcd_addr)) ? '' : t_data.cust_hom_pstcd_addr
      this.lv_ed_h_bldg_no.value = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.hom_bldg_no_encr)) ? '' : t_data.hom_bldg_no_encr
      this.lv_ed_h_remn_addr.value = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.cust_hom_dtlad_encr)) ? '' : t_data.cust_hom_dtlad_encr
      this.lv_ed_h_adtn_addr.value = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.hom_adtn_addr_encr)) ? '' : t_data.hom_adtn_addr_encr
      // this.lv_ed_h_new_addr_gb          = t_data.h_new_addr_gb

      // 자택전화번호
      this.lv_ed_h_telno_area.value = t_data.hom_dstno_encr
      this.lv_ed_h_telno_station.value = t_data.hom_tlnum_no_encr
      this.lv_ed_h_telno_seq.value = t_data.hom_bkno_sno_encr

      // 직장명
      this.lv_ed_o_name.value = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.cust_jobp_nm)) ? '' : t_data.cust_jobp_nm

      // 직장주소
      this.lv_ed_o_addr_zipcode1 = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.jobp_addr_pstcd01)) ? '' : t_data.jobp_addr_pstcd01
      this.lv_ed_o_addr_zipcode2 = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.jobp_addr_pstcd02)) ? '' : t_data.jobp_addr_pstcd02
      this.lv_ed_o_addr_zipcode3 = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.jobp_addr_whl_pstcd)) ? '' : t_data.jobp_addr_whl_pstcd
      this.lv_ed_o_addr_stdzt_yn.value = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.jobp_addr_stdzt_yn)) ? '' : t_data.jobp_addr_stdzt_yn

      this.lv_ed_o_zip_addr.value = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.cust_jobp_pstcd_addr)) ? '' : t_data.cust_jobp_pstcd_addr
      this.lv_ed_o_bldg_no.value = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.jobp_bldg_no_encr)) ? '' : t_data.jobp_bldg_no_encr
      this.lv_ed_o_remn_addr.value = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.cust_jobp_dtlad_encr)) ? '' : t_data.cust_jobp_dtlad_encr
      this.lv_ed_o_adtn_addr.value = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.jobp_adtn_addr_encr)) ? '' : t_data.jobp_adtn_addr_encr
      // this.lv_ed_o_new_addr_gb          = t_data.o_new_addr_gb

      // 직장전화번호
      this.lv_ed_o_telno_area.value = t_data.jobp_dstno_encr
      this.lv_ed_o_telno_station.value = t_data.jobp_tlnum_no_encr
      this.lv_ed_o_telno_seq.value = t_data.jobp_bkno_sno_encr

      // FAX번호
      this.lv_ed_faxno_area.value = t_data.fax_dstno_encr
      this.lv_ed_faxno_station.value = t_data.fax_tlnum_no_encr
      this.lv_ed_faxno_seq.value = t_data.fax_bkno_sno_encr

      // 휴대폰번호
      this.lv_cob_cp_telno_comp.value = t_data.bman_celph_dstno_encr
      this.lv_ed_cp_telno_station.value = t_data.bman_celph_tlnum_no_encr
      this.lv_ed_cp_telno_seq.value = t_data.bman_celph_bkno_sno_encr

      // EMAIL
      this.lv_ed_email_addr.value = t_data.cust_emai_addr_encr
      this.lv_ed_domain_name.value = t_data.cust_emai_dm_nm
    },

    /***********************************************************************************
     * Function명 : fn_cdi_select, fn_cdi_select_result
     * 설명       : 고객정보조회
     ***********************************************************************************/
    fn_cdi_select() {
      console.log('fn_cdi_select...')

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570120_S'
      this.eaiCommObj.params = {
        bfcer_incmr_sc_cd: this.lv_cu150000_info.bfcer_incmr_sc_cd,
        // bfcer_rlnm_no_knd_cd  : '01',
        bfcer_rlnm_no_knd_cd: this.lv_cu150000_info.bfcer_rlnm_no_knd_cd,
        fnd_cust_nm: this.lv_cu150000_info.fnd_cust_nm,
        rlnm_cnf_no_encr: this.lv_cu150000_info.rlnm_cnf_no_encr
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_cdi_select_result)
    },

    fn_cdi_select_result(pResultData) {
      console.log('fn_cdi_select_result...')

      let t_popupObj = {}
      let t_data = pResultData.data
      console.log(t_data)

      if (t_data.error_msg == '809900') {
        // 고객정보 표시
        this.fn_cust_info_base('P')

        let t_cdi_data = t_data.cu100000_O_00VO[0] // CDI고객정보
        let t_cdi_bp_data = t_data.cu100000_O_06VO[0] // CDI BP정보

        // CDI BP정보 매핑
        this.lv_cdi_bp_data = t_data.cu100000_O_06VO

        // CDI 측에 데이터 존재하는 경우(등록/수정)
        if (FSCommUtil.gfn_length(FSCommUtil.gfn_trim(t_cdi_bp_data.partner)) > 0) {
          console.log('==== CDI측에 데이터가 존재하는경우')

          // 고객명 매핑
          let suik_cust_nm = this.lv_ed_cust_nm.value

          // CDI 조회후 세팅이 정상적으로 안되서.수익증권 정보로 업데이트함
          t_cdi_data.hom_addr_stdzt_yn = this.lv_cu150000_info.hom_addr_stdzt_yn
          t_cdi_data.jobp_addr_stdzt_yn = this.lv_cu150000_info.jobp_addr_stdzt_yn

          // 주소구분 세팅
          if (!FSCommUtil.gfn_isNull(t_cdi_data.hom_dstno_encr) && FSCommUtil.gfn_isNull(this.lv_ed_h_new_addr_gb)) {
            this.lv_ed_h_new_addr_gb = '2'
          }

          if (!FSCommUtil.gfn_isNull(t_cdi_data.jobp_dstno_encr) && FSCommUtil.gfn_isNull(this.lv_ed_o_new_addr_gb)) {
            this.lv_ed_o_new_addr_gb = '2'
          }

          //  거주국코드는 CDI동기화 항목에 없어서 한국이 아닌경우 공백으로 나오는 부분 보완
          // 변경전 : 한국이 아닌경우 공백  -> 변경후 : PCM2000 기준으로 화면에 보여줌
          let cdi_resi_natn_cd = FSCommUtil.gfn_trim(t_cdi_data.fnd_dmcl_cntry_cd)
          let cdi_resi_yn = t_cdi_data.dwlr_yn

          if (cdi_resi_yn == 'N' && FSCommUtil.gfn_length(cdi_resi_natn_cd) != 3) {
            // 거주국코드는 CDI동기화 항목에 없어서 한국이 아닌경우 공백으로 나오는 부분 보완
            t_cdi_data.fnd_dmcl_cntry_cd = this.lv_cu150000_info.fnd_dmcl_cntry_cd
          }

          // 마케팅 여부에 따른 처리
          if (t_cdi_data.crd_int_yn2 == 'Y') {
            let crd_strymd1 = t_cdi_data.crd_strymd
            let crd_strymd2 = FSCommUtil.gfn_substr(crd_strymd1, 0, 8)
            this.lv_ed_crd_strymd.value = crd_strymd2

            if (FSCommUtil.gfn_length(t_cdi_data.crd_endymd) >= 8) {
              let crd_endymd1 = t_cdi_data.crd_endymd.replace(/-/g, '')
              let crd_endymd2 = FSCommUtil.gfn_substr(crd_endymd1, 0, 8)

              this.lv_ed_crd_endymd.value = crd_endymd2
              this.lv_mask_crd_endymd.value = FSCommUtil.gfn_dateReplace(1, t_cdi_data.crd_endymd)

              let crd_term = ''
              if (crd_endymd2 == '99991231') {
                crd_term = '99'
              } else {
                crd_term = Number(FSCommUtil.gfn_substr(crd_endymd2, 0, 4)) - Number(FSCommUtil.gfn_substr(crd_strymd2, 0, 4))
              }
              this.lv_ed_crd_term.value = crd_term
            }
            this.lv_cdi_crd_term = 'N'
          } else {
            t_cdi_data.crd_strymd = ''
          }

          // CDI미존재 값 CDD고객정보로 매핑 추가
          t_cdi_data.bfcer_lvht_sc_cd = this.lv_cu150000_info.bfcer_lvht_sc_cd
          t_cdi_data.bfcer_mail_trad_cd = this.lv_cu150000_info.bfcer_mail_trad_cd
          t_cdi_data.bfcer_fnd_blnc_ntc_sc_cd = this.lv_cu150000_info.bfcer_fnd_blnc_ntc_sc_cd
          t_cdi_data.crd_int_yn1 = this.lv_cu150000_info.crd_int_yn1

          if (this.lv_ed_cust_nm.value != this.lv_cu150000_info.fnd_cust_nm) {
            FSCommUtil.gfn_validate(this, 'CDD고객정보(' + this.lv_cu150000_info.fnd_cust_nm + ')와 개인고객정보(' + this.lv_ed_cust_nm.value + ')상이\n\n본사에서 확인후 정정처리요망!\n\n')

            // 수정 버튼 비활성화
            this.lv_btn_update = true
          }

          // 고객정보 매핑(CDI)
          this.fn_info_mapping(t_cdi_data, 'CDI')

          // 우편번호/주소구분 세팅
          this.fn_set_addr_gb()

          // 실명/법인번호 비활성화
          this.lv_ed_rname_no.disabled = true
          this.lv_ed_rname_no1.disabled = true
        } else {
          console.log('==== CDI측에 데이터가 존재하지 않는 경우(등록)')

          // CDI미존재 값 CDD고객정보로 매핑 추가
          t_cdi_data.bfcer_lvht_sc_cd = this.lv_cu150000_info.bfcer_lvht_sc_cd
          t_cdi_data.bfcer_mail_trad_cd = this.lv_cu150000_info.bfcer_mail_trad_cd
          t_cdi_data.bfcer_fnd_blnc_ntc_sc_cd = this.lv_cu150000_info.bfcer_fnd_blnc_ntc_sc_cd
          t_cdi_data.dwlr_yn = this.lv_cu150000_info.dwlr_yn
          t_cdi_data.crd_int_yn1 = this.lv_cu150000_info.crd_int_yn1
          t_cdi_data.bfcer_naty_cntry_cd = this.lv_cu150000_info.bfcer_naty_cntry_cd
          t_cdi_data.fnd_dmcl_cntry_cd = this.lv_cu150000_info.fnd_dmcl_cntry_cd

          // 고객정보 매핑(CDD)
          this.fn_info_mapping(t_cdi_data)

          // 생일입력
          let t_birth = this.fn_RnameNo()
          this.lv_cal_birth_dt.value = t_birth

          // 실명/법인번호 비활성화
          this.lv_ed_rname_no.disabled = true
          this.lv_ed_rname_no1.disabled = true
        }
      }
    },

    /***********************************************************************************
     * Function명 : fn_RnameNo
     * 설명       : 우편번호/주소구분 세팅
     ***********************************************************************************/

    fn_RnameNo() {
      console.log('fn_RnameNo...')

      let vBirth = ''
      let rrn_birth_dt = ''
      let rrn_birth_solun_gb = ''

      if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value) <= 0) {
        return vBirth
      }

      // 생일 관련하여 주민등록번호상 생일은 따로 저장.
      // 실명번호 구분이 주민 번호 이면
      if (this.lv_cob_rname_gb.value == '01') {
        let aYear = '19'
        let aDate = FSCommUtil.gfn_substr(this.lv_ed_rname_no.value, 0, 6)

        // 주민번호가 3이상이면 2000년도 출생
        if (Number(FSCommUtil.gfn_substr(this.lv_ed_rname_no.value, 7, 1)) > 2) {
          aYear = '20'
        }
        this.lv_cal_birth_dt.value = aYear + aDate
        vBirth = aYear + aDate

        rrn_birth_dt = aYear + aDate
        rrn_birth_solun_gb = this.lv_rad_birth_solun_gb.value

        this.lv_cu150000_info.rrn_birth_dt = rrn_birth_dt
        this.lv_cu150000_info.rrn_birth_solun_gb = rrn_birth_solun_gb
      }

      return vBirth
    },

    /***********************************************************************************
     * Function명 : fn_set_addr_gb
     * 설명       : 우편번호/주소구분 세팅
     ***********************************************************************************/

    fn_set_addr_gb() {
      console.log('fn_set_addr_gb...')

      //새우편번호 체계 적용에 따라 구우편번호/신우편번호 조회 상이하게 수정
      let chk_h_zipcode_no = FSCommUtil.gfn_trim(this.lv_cu150000_info.hom_addr_pstcd01) + FSCommUtil.gfn_trim(this.lv_cu150000_info.hom_addr_pstcd02)
      let chk_o_zipcode_no = FSCommUtil.gfn_trim(this.lv_cu150000_info.jobp_addr_pstcd01) + FSCommUtil.gfn_trim(this.lv_cu150000_info.jobp_addr_pstcd02)

      //자택 우편번호
      if (FSCommUtil.gfn_length(chk_h_zipcode_no) == 6) {
        //구 우편번호
        this.lv_ed_h_addr_zipcode_new.value = FSCommUtil.gfn_substr(chk_h_zipcode_no, 0, 3) + '-' + FSCommUtil.gfn_substr(chk_h_zipcode_no, 3, 3)
        FSCommUtil.gfn_validate(this, '자택 우편번호가 구 우편번호입니다. 5자리인 새 우편번호로 변경하십시오.')
      } else {
        //신 우편번호
        this.lv_ed_h_addr_zipcode_new.value = chk_h_zipcode_no
      }

      //직장 우편번호
      if (FSCommUtil.gfn_length(chk_o_zipcode_no) == 6) {
        //구 우편번호
        this.lv_ed_o_addr_zipcode_new.value = FSCommUtil.gfn_substr(chk_o_zipcode_no, 0, 3) + '-' + FSCommUtil.gfn_substr(chk_o_zipcode_no, 3, 3)
        FSCommUtil.gfn_validate(this, '직장 우편번호가 구 우편번호입니다. 5자리인 새 우편번호로 변경하십시오.')
      } else {
        //신 우편번호
        this.lv_ed_o_addr_zipcode_new.value = chk_o_zipcode_no
      }

      // 자택 주소구분 셋팅
      if (FSCommUtil.gfn_length(chk_h_zipcode_no) > 0) {
        if (FSCommUtil.gfn_length(chk_h_zipcode_no) == 6) {
          //구 우편번호
          this.lv_ed_h_new_addr_gb = '1'
        } else {
          // 신 우편번호
          this.lv_ed_h_new_addr_gb = '2'
        }
      }

      // 직장 주소구분 셋팅
      if (FSCommUtil.gfn_length(chk_o_zipcode_no) > 0) {
        if (FSCommUtil.gfn_length(chk_o_zipcode_no.length) == 6) {
          // 구 우편번호
          this.lv_ed_o_new_addr_gb = '1'
        } else {
          // 신 우편번호
          this.lv_ed_o_new_addr_gb = '2'
        }
      }
    },

    /***********************************************************************************
     * Function명 : fn_update, fn_UpdateResult, fn_submit_mapping, fn_CU1500_valueCheck
     * 설명       : 고객정보 수정
     ***********************************************************************************/

    async fn_Update(fatcaCheck) {
      console.log('fn_Update...')

      // 필수 값 체크
      this.lv_rdo_agnt_chk_yn.error = false

      if (FSCommUtil.gfn_isNull(this.lv_rdo_agnt_chk_yn.value)) {
        FSCommUtil.gfn_validate(this, '대리인여부을 선택하십시오.')
        this.lv_rdo_agnt_chk_yn.error = true
        document.querySelector('.mo-page__contents-wrapper').scrollTop = 0
        return
      }

      if (this.fn_CU1500_valueCheck() != true) {
        return
      }

      // 	국적,거주국:미국으로 변경시 FATCA(해외금융계좌신고제도) 체크로직
      // 	국적,거주국이 한국이 아닌경우 fatca,crs 체크
      if (fatcaCheck != 'Y' && (this.lv_cob_ctzn_natn_cd.value != 'KR' || this.lv_cob_resi_natn_cd.value != 'KR')) {
        // 개인일 경우 FATCA 체크
        if (this.lv_corp_gb == 'N') {
          let t_fatca_check_data = await FSFatcaUtil.fatca_check(this, this.lv_ed_cust_no.value, 'CU1500', this.fn_OpenPopup, this.fn_AlertPopup)
          if (t_fatca_check_data != true) {
            // this.lv_btn_update = true
            // if( FSCommUtil.gfn_isNull(this.lv_fatca_check_data.isFlag) ) {
            //   t_popupObj = {
            //     content: 'FATCA(해외금융계좌신고제도) 체크가 정상적으로 종료되지 않았습니다.'
            //   }
            //   this.fn_AlertPopup(0, t_popupObj)
            // }
            return
          }

          // 법인은 불가
        } else {
          FSCommUtil.gfn_validate(this, '법인은 국적/거주국 한국만 가능')
          return
        }
      }

      // 화면 세팅값 submitData 매핑
      this.fn_submit_mapping()

      // CDI에 보내는 변수 세팅(노드값, 인디케이터값)
      this.lv_submit_data.cdi_sync_yn = 'Y'

      if (this.lv_chk_cdi_sync_yn.value == 1) {
        this.lv_submit_data.proc_gb = 'U'
        this.lv_submit_data.cust_info_sync_yn = 'Y'
      } else {
        this.lv_submit_data.proc_gb = 'U1'
        this.lv_submit_data.cust_info_sync_yn = 'N'
      }

      this.lv_submit_data.tr_code = 'CU1500'
      this.lv_submit_data.sup_id = ''
      this.lv_submit_data.aprv_sno = ''
      this.lv_submit_data.media = 'I'
      this.lv_submit_data.ars_yn = 'N'

      // CDI BP정보 매핑
      this.lv_submit_data.CU150000_O_06VO = this.lv_cdi_bp_data
      // console.log(this.lv_submit_data)

      // 수정 버튼 비활성화
      this.lv_btn_update = true

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'I'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570294_S'
      this.eaiCommObj.params = this.lv_submit_data

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_UpdateResult)
    },

    fn_UpdateResult(pResultData) {
      console.log('fn_UpdateResult...')

      let t_popupObj = {}
      let t_data = pResultData.data
      console.log(t_data)

      t_popupObj.content = FSCommUtil.gfn_isNum(t_data.error_msg) ? FSMessageUtil.commMessage([])[t_data.error_msg] : t_data.error_msg
      this.fn_AlertPopup(0, t_popupObj)

      if (t_data.error_msg == '809902') {
        if (this.lv_corp_gb == 'N') {
          console.log('==== 개인일 경우에만 전자서식 출력')
          //this.fn_ReportPrint()
          let t_popupObj = {
            cancel: false,
            confirm: true,
            confirmFunc: this.fn_ReportPrint,
            confirmData: {
              bfcer_cust_no: this.lv_ed_cust_no.value
            },
            content: ['수정 되었습니다.']
          }
          this.fn_AlertPopup(9, t_popupObj)
        }
      }
    },

    fn_submit_mapping() {
      console.log('fn_submit_mapping...')

      // ===================================== 개인/법인 공통
      this.lv_submit_data.bfcer_cust_no = this.lv_ed_cust_no.value // 고객번호

      this.lv_submit_data.bfcer_rlnm_no_knd_cd = this.lv_cob_rname_gb.value // 실명번호종류

      this.lv_submit_data.fnd_cust_nm = this.lv_cust_nm.value // 고객명
      this.lv_submit_data.fnd_cust_eng_nm = this.lv_cust_enm.value // 고객영문명

      this.lv_submit_data.bfcer_naty_cntry_cd = this.lv_cob_ctzn_natn_cd.value // 국적
      this.lv_submit_data.fnd_dmcl_cntry_cd = this.lv_cob_resi_natn_cd.value // 거주국

      this.lv_submit_data.bfcer_incmr_sc_cd = this.lv_cob_inc_earner_gb.value // 소득자구분

      this.lv_submit_data.bfcer_mail_trad_cd = this.lv_rad_mail_recv_gb.value // 우편물통보처

      this.lv_submit_data.bfcer_fnd_blnc_ntc_sc_cd = this.lv_rad_fund_recv_gb.value // 펀드잔고통보처

      this.lv_submit_data.crd_int_yn1 = this.lv_rad_crd_int_yn1.value // 계약체결

      this.lv_submit_data.crd_int_yn2 = this.lv_rad_crd_int_yn2.value // 마케팅
      this.lv_submit_data.mkt_cnsnt_avl_end_ymd = this.lv_mask_crd_endymd.value.replace(/-/g, '') // 마케팅유효일자

      // 연락방식
      this.lv_submit_data.mkt_cnvs_mth_tel_yn = this.lv_cob_mkt_cnvs_mth_tel_yn.value ? 'Y' : 'N'
      this.lv_submit_data.mkt_cnvs_mth_sms_yn = this.lv_cob_mkt_cnvs_mth_sms_yn.value ? 'Y' : 'N'
      this.lv_submit_data.mkt_cnvs_mth_email_yn = this.lv_cob_mkt_cnvs_mth_email_yn.value ? 'Y' : 'N'
      this.lv_submit_data.mkt_cnvs_mth_pst_yn = this.lv_cob_mkt_cnvs_mth_pst_yn.value ? 'Y' : 'N'

      this.lv_submit_data.mkt_new_gb = this.lv_ed_mkt_new_gb.value
      this.lv_submit_data.dl_cust_yn = this.lv_ed_mkt_cust_deal_yn.value

      this.lv_submit_data.crd_term = this.lv_ed_crd_term.value
      this.lv_submit_data.crd_strymd = this.lv_ed_crd_strymd.value
      this.lv_submit_data.crd_endymd = this.lv_ed_crd_endymd.value

      // 연락처정보

      // 자택주소
      this.lv_submit_data.hom_addr_pstcd01 = this.lv_ed_h_addr_zipcode1
      this.lv_submit_data.hom_addr_pstcd02 = this.lv_ed_h_addr_zipcode2
      this.lv_submit_data.hom_addr_whl_pstcd = this.lv_ed_h_addr_zipcode_new.value // 우편번호
      this.lv_submit_data.hom_addr_stdzt_yn = this.lv_ed_h_addr_stdzt_yn.value // 주소표준화여부
      this.lv_submit_data.h_new_addr_gb = this.lv_ed_h_new_addr_gb // 주소구분

      this.lv_submit_data.cust_hom_pstcd_addr = this.lv_ed_h_zip_addr.value // 우편번호주소
      this.lv_submit_data.hom_bldg_no_encr = this.lv_ed_h_bldg_no.value // 건물번호
      this.lv_submit_data.cust_hom_dtlad_encr = this.lv_ed_h_remn_addr.value // 상세주소
      this.lv_submit_data.hom_adtn_addr_encr = this.lv_ed_h_adtn_addr.value // 부가주소

      // 자택전화번호
      this.lv_submit_data.hom_dstno_encr = this.lv_ed_h_telno_area.value
      this.lv_submit_data.hom_tlnum_no_encr = this.lv_ed_h_telno_station.value
      this.lv_submit_data.hom_bkno_sno_encr = this.lv_ed_h_telno_seq.value

      // 직장명
      this.lv_submit_data.cust_jobp_nm = this.lv_ed_o_name.value

      // 직장주소
      this.lv_submit_data.jobp_addr_pstcd01 = this.lv_ed_o_addr_zipcode1
      this.lv_submit_data.jobp_addr_pstcd02 = this.lv_ed_o_addr_zipcode2
      this.lv_submit_data.jobp_addr_whl_pstcd = this.lv_ed_o_addr_zipcode_new.value // 우편번호
      this.lv_submit_data.jobp_addr_stdzt_yn = this.lv_ed_o_addr_stdzt_yn.value // 주소표준화여부
      this.lv_submit_data.o_new_addr_gb = this.lv_ed_o_new_addr_gb // 주소구분

      this.lv_submit_data.cust_jobp_pstcd_addr = this.lv_ed_o_zip_addr.value // 우편번호주소
      this.lv_submit_data.jobp_bldg_no_encr = this.lv_ed_o_bldg_no.value // 건물번호
      this.lv_submit_data.cust_jobp_dtlad_encr = this.lv_ed_o_remn_addr.value // 상세주소
      this.lv_submit_data.jobp_adtn_addr_encr = this.lv_ed_o_adtn_addr.value // 부가주소

      // 직장전화번호
      this.lv_submit_data.jobp_dstno_encr = this.lv_ed_o_telno_area.value
      this.lv_submit_data.jobp_tlnum_no_encr = this.lv_ed_o_telno_station.value
      this.lv_submit_data.jobp_bkno_sno_encr = this.lv_ed_o_telno_seq.value

      // FAX번호
      this.lv_submit_data.fax_dstno_encr = this.lv_ed_faxno_area.value
      this.lv_submit_data.fax_tlnum_no_encr = this.lv_ed_faxno_station.value
      this.lv_submit_data.fax_bkno_sno_encr = this.lv_ed_faxno_seq.value

      // 휴대폰번호
      this.lv_submit_data.bman_celph_dstno_encr = this.lv_cob_cp_telno_comp.value
      this.lv_submit_data.bman_celph_tlnum_no_encr = this.lv_ed_cp_telno_station.value
      this.lv_submit_data.bman_celph_bkno_sno_encr = this.lv_ed_cp_telno_seq.value

      // EMAIL
      this.lv_submit_data.cust_emai_addr_encr = this.lv_ed_email_addr.value
      this.lv_submit_data.cust_emai_dm_nm = this.lv_ed_domain_name.value

      // 개인
      if (this.lv_corp_gb != 'Y') {
        // 기본정보
        // this.lv_submit_data.rlnm_cnf_no_encr        = this.lv_ed_rname_no.value.replace(/-/g, '')     // 실명번호

        this.lv_submit_data.dwlr_yn = this.lv_rad_resi_yn.value // 거주자여부

        this.lv_submit_data.bfcer_lvht_sc_cd = this.lv_cob_life_gb.value // 우대구분

        this.lv_submit_data.cust_birth_mmdd = this.lv_cal_birth_dt.value.replace(/-/g, '') // 생일
        this.lv_submit_data.bfcer_slrc_lnrc_sc_cd = this.lv_rad_birth_solun_gb.value // 생일구분

        // this.lv_bf_crd_int_yn1.value      = t_data.busn_trust_cnsnt_yn
      }

      // 법인
      else {
        // 기본정보
        this.lv_submit_data.rlnm_cnf_no_encr = this.lv_ed_rname_no1.value // 실명번호

        this.lv_submit_data.bfcer_resnt_nm = this.lv_ed_res_nm.value // 대표자명

        this.lv_submit_data.bkkp_nm = this.lv_ed_addrmrk_nm.value // 부기명/부서명

        this.lv_submit_data.bfcer_fnd_taxt_sc_cd = this.lv_cob_tax_gb.value // 과세구분
      }
    },

    fn_CU1500_valueCheck() {
      console.log('fn_CU1500_valueCheck...')

      // if( application.gv_adm_eno.length < 1 ) {
      //   var bUpdated = this.gfn_dsIsUpdated(this.Ds_cu1500)

      //   if (!bUpdated) {
      //     if (this.lv_chk_cdi_sync_yn.value != 1) {
      //       this.gfn_getMessage("alert", "수정된 사항이 없습니다. 수정할 정보를 확인하여 주십시요.")
      //       return false
      //     }
      //   }
      // }

      let t_return = true

      let t_vaildList1 = [
        this.lv_rad_crd_int_yn2 // 마케팅여부
      ]

      if (FSCommUtil.gfn_isValidList(this, t_vaildList1, t_return)) {
        t_return = false
      }

      // 개인
      if (this.lv_corp_gb != 'Y') {
        let t_vaildList2 = [
          this.lv_ed_rname_no, // 실명번호
          this.lv_cob_rname_gb, // 실명번호종류
          this.lv_ed_cust_nm, // 고객명
          this.lv_cob_ctzn_natn_cd, // 국적
          this.lv_cob_resi_natn_cd, // 거주국
          this.lv_cob_inc_earner_gb, // 소득자구분
          this.lv_rad_resi_yn, // 거주자구분
          this.lv_rad_mail_recv_gb, // 우편물통보처
          this.lv_rad_fund_recv_gb // 펀드잔고통보처
        ]

        if (FSCommUtil.gfn_isValidList(this, t_vaildList2, t_return)) {
          t_return = false
        }

        // 초기화

        // 자택주소
        this.lv_ed_h_addr_zipcode_new.error = false
        this.lv_ed_h_zip_addr.error = false
        this.lv_ed_h_bldg_no.error = false
        this.lv_ed_h_remn_addr.error = false
        this.lv_ed_h_adtn_addr.error = false

        // 자택전화번호
        this.lv_ed_h_telno_area.error = false
        this.lv_ed_h_telno_station.error = false
        this.lv_ed_h_telno_seq.error = false

        // 직장주소
        this.lv_ed_o_addr_zipcode_new.error = false
        this.lv_ed_o_zip_addr.error = false
        this.lv_ed_o_bldg_no.error = false
        this.lv_ed_o_remn_addr.error = false
        this.lv_ed_o_adtn_addr.error = false

        // 직장전화번호
        this.lv_ed_o_telno_area.error = false
        this.lv_ed_o_telno_station.error = false
        this.lv_ed_o_telno_seq.error = false

        // 직장명
        this.lv_ed_o_name.error = false

        // 휴대폰번호
        this.lv_cob_cp_telno_comp.error = false
        this.lv_ed_cp_telno_station.error = false
        this.lv_ed_cp_telno_seq.error = false

        // EMAIL
        this.lv_ed_email_addr.error = false
        this.lv_ed_domain_name.error = false

        // 우편물통보처 값에 따라 필수 값 체크
        // 자택
        if (this.lv_rad_mail_recv_gb.value == '11') {
          if (FSCommUtil.gfn_isNull(this.lv_ed_h_zip_addr.value)) {
            if (t_return) {
              FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')
            }
            this.lv_ed_h_addr_zipcode_new.error = true
            this.lv_ed_h_zip_addr.error = true
            this.lv_ed_h_bldg_no.error = true
            this.lv_ed_h_remn_addr.error = true
            this.lv_ed_h_adtn_addr.error = true

            t_return = false
          }
        }

        // 직장
        if (this.lv_rad_mail_recv_gb.value == '12') {
          if (FSCommUtil.gfn_isNull(this.lv_ed_o_zip_addr.value)) {
            if (t_return) {
              FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')
            }
            this.lv_ed_o_addr_zipcode_new.error = true
            this.lv_ed_o_zip_addr.error = true
            this.lv_ed_o_bldg_no.error = true
            this.lv_ed_o_remn_addr.error = true
            this.lv_ed_o_adtn_addr.error = true

            t_return = false
          }
        }

        // 스마트
        if (this.lv_rad_mail_recv_gb.value == '15') {
          if (FSCommUtil.gfn_isNull(this.lv_cob_cp_telno_comp.value) || FSCommUtil.gfn_isNull(this.lv_ed_cp_telno_station.value) || FSCommUtil.gfn_isNull(this.lv_ed_cp_telno_seq.value)) {
            if (t_return) {
              FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')

              if (FSCommUtil.gfn_isNull(this.lv_ed_cp_telno_station.value)) {
                this.$refs['ed_cp_telno_station'].focus()
              } else if (FSCommUtil.gfn_isNull(this.lv_ed_cp_telno_seq.value)) {
                this.$refs['ed_cp_telno_seq'].focus()
              }
            }
            this.lv_cob_cp_telno_comp.error = true
            this.lv_ed_cp_telno_station.error = true
            this.lv_ed_cp_telno_seq.error = true

            t_return = false
          }

          if (FSCommUtil.gfn_isNull(this.lv_ed_email_addr.value) || FSCommUtil.gfn_isNull(this.lv_ed_domain_name.value)) {
            if (t_return) {
              FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')
            }
            this.lv_ed_email_addr.error = true
            this.lv_ed_domain_name.error = true

            t_return = false
          }
        }

        // 우편물통보처, 펀드잔고통보처 값에 따라 필수 값 체크
        else if (this.lv_rad_mail_recv_gb.value == '14' || this.lv_rad_fund_recv_gb.value == '14') {
          if (FSCommUtil.gfn_isNull(this.lv_ed_email_addr.value) || FSCommUtil.gfn_isNull(this.lv_ed_domain_name.value)) {
            if (t_return) {
              FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')
            }
            this.lv_ed_email_addr.error = true
            this.lv_ed_domain_name.error = true

            t_return = false
          }
        }

        if (t_return) {
          // 실명번호 체크
          // if( FSCommUtil.gfn_length(this.lv_ed_rname_no.value) < 13  ) {
          //   FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
          //   this.lv_ed_rname_no.error = true
          //   return false
          // }

          // 자택, 직장주소 체크
          if (FSCommUtil.gfn_length(this.lv_ed_h_addr_zipcode1) == 0 && FSCommUtil.gfn_length(this.lv_ed_o_addr_zipcode1) == 0) {
            FSCommUtil.gfn_validate(this, '자택 또는 직장주소 우편번호를 입력해 주십시오.')
            this.lv_ed_h_addr_zipcode_new.error = true
            this.lv_ed_o_addr_zipcode_new.error = true
            return false
          }

          if (FSCommUtil.gfn_length(this.lv_ed_h_addr_zipcode2) == 0 && FSCommUtil.gfn_length(this.lv_ed_o_addr_zipcode2) == 0) {
            FSCommUtil.gfn_validate(this, '자택 또는 직장주소 우편번호를 입력해 주십시오.')
            this.lv_ed_h_addr_zipcode_new.error = true
            this.lv_ed_o_addr_zipcode_new.error = true
            return false
          }

          let vH_addr_zipcode = FSCommUtil.gfn_trim(this.lv_ed_h_addr_zipcode_new.value)
          let vH_bldg_no = FSCommUtil.gfn_trim(this.lv_ed_h_bldg_no.value)
          let vO_addr_zipcode = FSCommUtil.gfn_trim(this.lv_ed_o_addr_zipcode_new.value)
          let vO_bldg_no = FSCommUtil.gfn_trim(this.lv_ed_o_bldg_no.value)

          // 자택 우편번호 값 존재하고 직장 우편번호 값 미존재할 경우
          if (FSCommUtil.gfn_length(this.lv_ed_h_addr_zipcode1) > 0 && FSCommUtil.gfn_length(this.lv_ed_o_addr_zipcode1) == 0) {
            if (FSCommUtil.gfn_length(this.lv_ed_h_zip_addr.value) == 0) {
              FSCommUtil.gfn_validate(this, '자택 우편번호 주소를 입력해 주십시오.')
              this.lv_ed_h_addr_zipcode_new.error = true
              return false
            }

            // 도로명주소만 입력가능
            if (FSCommUtil.gfn_length(vH_addr_zipcode) > 5) {
              FSCommUtil.gfn_validate(this, '자택 도로명주소로 입력하세요(우편번호 체계오류)')
              this.lv_ed_h_addr_zipcode_new.error = true
              return false
            }

            // 도로명주소일경우, 빌딩주소없으면 BP등록시 오류 발생 방지
            if (FSCommUtil.gfn_isNull(vH_bldg_no)) {
              FSCommUtil.gfn_validate(this, '자택 도로명주소로 입력하세요 (빌딩주소필수)')
              this.lv_ed_h_addr_zipcode_new.error = true
              this.lv_ed_h_bldg_no.error = true
              return false
            }

            // 자택 우편번호 값 미존재하고 직장 우편번호 값 존재할 경우
          } else if (FSCommUtil.gfn_length(this.lv_ed_h_addr_zipcode1) == 0 && FSCommUtil.gfn_length(this.lv_ed_o_addr_zipcode1) > 0) {
            if (FSCommUtil.gfn_length(this.lv_ed_o_zip_addr.value) == 0) {
              FSCommUtil.gfn_validate(this, '직장 우편번호 주소를 입력해 주십시오.')
              this.lv_ed_o_addr_zipcode_new.error = true
              return false
            }

            // 도로명주소만 입력가능
            if (FSCommUtil.gfn_length(vO_addr_zipcode) > 5) {
              FSCommUtil.gfn_validate(this, '직장 도로명주소로 입력하세요(우편번호 체계오류)')
              this.lv_ed_o_addr_zipcode_new.error = true
              return false
            }

            // 도로명주소일경우, 빌딩주소없으면 BP등록시 오류 발생 방지
            if (FSCommUtil.gfn_isNull(vO_bldg_no)) {
              FSCommUtil.gfn_validate(this, '직장 도로명주소로 입력하세요 (빌딩주소필수)')
              this.lv_ed_o_addr_zipcode_new.error = true
              this.lv_ed_o_bldg_no.error = true
              return false
            }

            // 자택 우편번호 값 직장 우편번호 값 둘다 존재할 경우
          } else if (FSCommUtil.gfn_length(this.lv_ed_h_addr_zipcode1) > 0 && FSCommUtil.gfn_length(this.lv_ed_o_addr_zipcode1) > 0) {
            // 자택 우편번호 값 존재할 경우
            if (FSCommUtil.gfn_length(this.lv_ed_h_addr_zipcode1) > 0) {
              if (FSCommUtil.gfn_length(this.lv_ed_h_zip_addr.value) == 0) {
                FSCommUtil.gfn_validate(this, '자택 우편번호 주소를 입력해 주십시오.')
                this.lv_ed_h_addr_zipcode_new.error = true
                return false
              }

              // 도로명주소만 입력가능
              if (FSCommUtil.gfn_length(vH_addr_zipcode) > 5) {
                FSCommUtil.gfn_validate(this, '자택 도로명주소로 입력하세요(우편번호 체계오류)')
                this.lv_ed_h_addr_zipcode_new.error = true
                return false
              }

              // 도로명주소일경우, 빌딩주소없으면 BP등록시 오류 발생 방지
              if (FSCommUtil.gfn_isNull(vH_bldg_no)) {
                FSCommUtil.gfn_validate(this, '자택 도로명주소로 입력하세요 (빌딩주소필수)')
                this.lv_ed_h_addr_zipcode_new.error = true
                this.lv_ed_h_bldg_no.error = true
                return false
              }
            }

            // 직장 우편번호 값 존재할 경우
            if (FSCommUtil.gfn_length(this.lv_ed_o_addr_zipcode1) > 0) {
              if (FSCommUtil.gfn_length(this.lv_ed_o_zip_addr.value) == 0) {
                FSCommUtil.gfn_validate(this, '직장 우편번호 주소를 입력해 주십시오.')
                this.lv_ed_o_addr_zipcode_new.error = true
                return false
              }

              // 도로명주소만 입력가능
              if (FSCommUtil.gfn_length(vO_addr_zipcode) > 5) {
                FSCommUtil.gfn_validate(this, '직장 도로명주소로 입력하세요(우편번호 체계오류)')
                this.lv_ed_o_addr_zipcode_new.error = true
                return false
              }

              // 도로명주소일경우, 빌딩주소없으면 BP등록시 오류 발생 방지
              if (FSCommUtil.gfn_isNull(vO_bldg_no)) {
                FSCommUtil.gfn_validate(this, '직장 도로명주소로 입력하세요 (빌딩주소필수)')
                this.lv_ed_o_addr_zipcode_new.error = true
                this.lv_ed_o_bldg_no.error = true
                return false
              }
            }
          }

          // 직장주소있는데 직장명 없는경우
          if (FSCommUtil.gfn_length(this.lv_ed_o_name.value) == 0 && FSCommUtil.gfn_length(this.lv_ed_o_zip_addr.value) > 0) {
            FSCommUtil.gfn_validate(this, '직장명을 입력하세요.')
            this.lv_ed_o_name.error = true
            this.$refs['ed_o_name'].focus()
            return false
          }

          // 자택전화, 직장전화 지역번호 값 둘다 미존재할 경우
          if (FSCommUtil.gfn_length(this.lv_ed_h_telno_area.value) == 0 && FSCommUtil.gfn_length(this.lv_ed_o_telno_area.value) == 0) {
            FSCommUtil.gfn_validate(this, '자택 또는 직장전화 지역번호를 입력해 주십시오.')
            this.lv_ed_h_telno_area.error = true
            this.lv_ed_o_telno_area.error = true
            this.$refs['ed_h_telno_area'].focus()
            return false

            // 자택전화 지역번호 값 존재하고 직장전화 지역번호 값 미존재할 경우
          } else if (FSCommUtil.gfn_length(this.lv_ed_h_telno_area.value) > 0 && FSCommUtil.gfn_length(this.lv_ed_o_telno_area.value) == 0) {
            if (FSCommUtil.gfn_length(this.lv_ed_h_telno_station.value) == 0) {
              FSCommUtil.gfn_validate(this, '자택전화국 번호를 입력해 주십시오.')
              this.lv_ed_h_telno_station.error = true
              this.$refs['ed_h_telno_station'].focus()
              return false
            }

            if (FSCommUtil.gfn_length(this.lv_ed_h_telno_seq.value) == 0) {
              FSCommUtil.gfn_validate(this, '자택전화 일련번호를 입력해 주십시오.')
              this.lv_ed_h_telno_seq.error = true
              this.$refs['ed_h_telno_seq'].focus()
              return false
            }

            // 자택전화 지역번호 값 미존재하고 직장전화 지역번호 값 존재할 경우
          } else if (FSCommUtil.gfn_length(this.lv_ed_h_telno_area.value) == 0 && FSCommUtil.gfn_length(this.lv_ed_o_telno_area.value) > 0) {
            if (FSCommUtil.gfn_length(this.lv_ed_o_telno_station.value) == 0) {
              FSCommUtil.gfn_validate(this, '직장전화국 번호를 입력해 주십시오.')
              this.lv_ed_o_telno_station.error = true
              this.$refs['ed_o_telno_station'].focus()
              return false
            }

            if (FSCommUtil.gfn_length(this.lv_ed_o_telno_seq.value) == 0) {
              FSCommUtil.gfn_validate(this, '직장전화 일련번호를 입력해 주십시오.')
              this.lv_ed_o_telno_seq.error = true
              this.$refs['ed_o_telno_seq'].focus()
              return false
            }

            // 자택전화, 직장전화 지역번호 값 둘다 존재할 경우
          } else if (FSCommUtil.gfn_length(this.lv_ed_h_telno_area.value) > 0 && FSCommUtil.gfn_length(this.lv_ed_o_telno_area.value) > 0) {
            // 자택전화 지역번호 값 존재할 경우
            if (FSCommUtil.gfn_length(this.lv_ed_h_telno_area.value) > 0) {
              if (FSCommUtil.gfn_length(this.lv_ed_h_telno_station.value) == 0) {
                FSCommUtil.gfn_validate(this, '자택전화국 번호를 입력해 주십시오.')
                this.lv_ed_h_telno_station.error = true
                this.$refs['ed_h_telno_station'].focus()
                return false
              }

              if (FSCommUtil.gfn_length(this.lv_ed_h_telno_seq.value) == 0) {
                FSCommUtil.gfn_validate(this, '자택전화 일련번호를 입력해 주십시오.')
                this.lv_ed_h_telno_seq.error = true
                this.$refs['ed_h_telno_seq'].focus()
                return false
              }
            }

            // 직장전화 지역번호 값 존재할 경우
            if (FSCommUtil.gfn_length(this.lv_ed_o_telno_area.value) > 0) {
              if (FSCommUtil.gfn_length(this.lv_ed_o_telno_station.value) == 0) {
                FSCommUtil.gfn_validate(this, '직장전화국 번호를 입력해 주십시오.')
                this.lv_ed_o_telno_station.error = true
                this.$refs['ed_o_telno_station'].focus()
                return false
              }

              if (FSCommUtil.gfn_length(this.lv_ed_o_telno_seq.value) == 0) {
                FSCommUtil.gfn_validate(this, '직장전화 일련번호를 입력해 주십시오.')
                this.lv_ed_o_telno_seq.error = true
                this.$refs['ed_o_telno_seq'].focus()
                return false
              }
            }
          }

          // 국적, 거주국, 소득자구분, 거주자여부, 생계형구분 체크
          this.lv_cob_ctzn_natn_cd.error = false
          this.lv_cob_resi_natn_cd.error = false
          this.lv_cob_inc_earner_gb.error = false
          this.lv_rad_resi_yn.error = false
          this.lv_cob_life_gb.error = false

          // 주민등록번호
          if (this.lv_cob_rname_gb.value == '01') {
            if (this.lv_cob_ctzn_natn_cd.value != 'KR') {
              FSCommUtil.gfn_validate(this, '국적이 올바르지 않습니다.')
              this.lv_cob_ctzn_natn_cd.error = true
              return false
            }

            if (this.lv_cob_resi_natn_cd.value != 'KR') {
              FSCommUtil.gfn_validate(this, '거주국이 올바르지 않습니다.')
              this.lv_cob_resi_natn_cd.error = true
              return false
            }

            if (this.lv_cob_inc_earner_gb.value != '111') {
              FSCommUtil.gfn_validate(this, '소득자 구분이 올바르지 않습니다.')
              this.lv_cob_inc_earner_gb.error = true
              return false
            }

            if (this.lv_rad_resi_yn.value != 'Y') {
              FSCommUtil.gfn_validate(this, '거주자여부가 올바르지 않습니다.')
              this.lv_rad_resi_yn.error = true
              return false
            }

            // 재외국인등록번호
          } else if (this.lv_cob_rname_gb.value == '03') {
            if (this.lv_cob_ctzn_natn_cd.value != 'KR') {
              FSCommUtil.gfn_validate(this, '국적이 올바르지 않습니다.')
              this.lv_cob_ctzn_natn_cd.error = true
              return false
            }

            if (this.lv_cob_resi_natn_cd.value != 'KR') {
              FSCommUtil.gfn_validate(this, '거주국이 올바르지 않습니다.')
              this.lv_cob_resi_natn_cd.error = true
              return false
            }

            if (this.lv_cob_inc_earner_gb.value != '122') {
              FSCommUtil.gfn_validate(this, '소득자 구분이 올바르지 않습니다.')
              this.lv_cob_inc_earner_gb.error = true
              return false
            }

            if (this.lv_rad_resi_yn.value != 'N') {
              FSCommUtil.gfn_validate(this, '거주자여부가 올바르지 않습니다.')
              this.lv_rad_resi_yn.error = true
              return false
            }

            // 외국인등록번호
          } else if (this.lv_cob_rname_gb.value == '04') {
            if (this.lv_cob_ctzn_natn_cd.value == 'KR') {
              FSCommUtil.gfn_validate(this, '국적이 올바르지 않습니다.')
              this.lv_cob_ctzn_natn_cd.error = true
              return false
            }

            if (this.lv_rad_resi_yn.value == 'Y') {
              if (this.lv_cob_resi_natn_cd.value != 'KR') {
                FSCommUtil.gfn_validate(this, '거주국이 올바르지 않습니다.')
                this.lv_cob_resi_natn_cd.error = true
                return false
              }
            } else if (this.lv_rad_resi_yn.value == 'N') {
              if (this.lv_cob_resi_natn_cd.value == 'KR') {
                FSCommUtil.gfn_validate(this, '거주국이 올바르지 않습니다.')
                this.lv_cob_resi_natn_cd.error = true
                return false
              }
            }

            if (this.lv_cob_inc_earner_gb.value != '131') {
              FSCommUtil.gfn_validate(this, '소득자 구분이 올바르지 않습니다.')
              this.lv_cob_inc_earner_gb.error = true
              return false
            }

            // 국내거소신고번호
          } else if (this.lv_cob_rname_gb.value == '05') {
            if (this.lv_cob_resi_natn_cd.value != 'KR') {
              FSCommUtil.gfn_validate(this, '거주국이 올바르지 않습니다.')
              this.lv_cob_resi_natn_cd.error = true
              return false
            }

            if (this.lv_cob_inc_earner_gb.value != '141') {
              FSCommUtil.gfn_validate(this, '소득자 구분이 올바르지 않습니다.')
              this.lv_cob_inc_earner_gb.error = true
              return false
            }

            if (this.lv_rad_resi_yn.value != 'Y') {
              FSCommUtil.gfn_validate(this, '거주자여부가 올바르지 않습니다.')
              this.lv_rad_resi_yn.error = true
              return false
            }

            // 여권번호 등
          } else if (this.lv_cob_rname_gb.value == '06') {
            if (this.lv_rad_resi_yn.value == 'Y') {
              if (this.lv_cob_resi_natn_cd.value != 'KR') {
                FSCommUtil.gfn_validate(this, '거주국이 올바르지 않습니다.')
                this.lv_cob_resi_natn_cd.error = true
                return false
              }
            } else if (this.lv_rad_resi_yn.value == 'N') {
              if (this.lv_cob_resi_natn_cd.value == 'KR') {
                FSCommUtil.gfn_validate(this, '거주국이 올바르지 않습니다.')
                this.lv_cob_resi_natn_cd.error = true
                return false
              }
            }

            if (this.lv_cob_inc_earner_gb.value != '121') {
              FSCommUtil.gfn_validate(this, '소득자 구분이 올바르지 않습니다.')
              this.lv_cob_inc_earner_gb.error = true
              return false
            }
          }

          // 생계형구분 체크
          if (this.lv_cob_life_gb.value != '0') {
            if (this.lv_rad_resi_yn.value != 'Y') {
              FSCommUtil.gfn_validate(this, '생계형구분은 거주자만 변경 가능합니다.')
              this.lv_cob_life_gb.error = true
              return false
            }
          }
        } else {
          return false
        }
      }

      // 법인
      else {
        let t_vaildList3 = [
          this.lv_ed_rname_no1, // 실명번호
          this.lv_cob_rname_gb, // 실명번호종류
          this.lv_ed_cust_nm, // 고객명
          this.lv_cob_ctzn_natn_cd, // 국적
          this.lv_cob_resi_natn_cd, // 거주국
          this.lv_cob_inc_earner_gb, // 소득자구분
          this.lv_cob_tax_gb, // 과세구분
          this.lv_rad_mail_recv_gb, // 우편물통보처
          this.lv_rad_fund_recv_gb // 펀드잔고통보처
        ]

        if (FSCommUtil.gfn_isValidList(this, t_vaildList3, t_return)) {
          t_return = false
        }

        // 초기화

        // 직장주소
        this.lv_ed_o_addr_zipcode_new.error = false
        this.lv_ed_o_zip_addr.error = false
        this.lv_ed_o_bldg_no.error = false
        this.lv_ed_o_remn_addr.error = false
        this.lv_ed_o_adtn_addr.error = false

        // 직장전화번호
        this.lv_ed_o_telno_area.error = false
        this.lv_ed_o_telno_station.error = false
        this.lv_ed_o_telno_seq.error = false

        // 직장명
        this.lv_ed_o_name.error = false

        // EMAIL
        this.lv_ed_email_addr.error = false
        this.lv_ed_domain_name.error = false

        // 우편물통보처 체크

        // 자택, 스마트일 경우
        if (this.lv_rad_mail_recv_gb.value == '11' || this.lv_rad_mail_recv_gb.value == '15') {
          if (t_return) {
            FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')
          }
          this.lv_rad_mail_recv_gb.error = true

          t_return = false
        }

        // 우편물통보처, 펀드잔고통보처 값에 따라 필수 값 체크
        if (this.lv_rad_mail_recv_gb.value == '14' || this.lv_rad_fund_recv_gb.value == '14') {
          if (FSCommUtil.gfn_isNull(this.lv_ed_email_addr.value) || FSCommUtil.gfn_isNull(this.lv_ed_domain_name.value)) {
            if (t_return) {
              FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')
            }
            this.lv_ed_email_addr.error = true
            this.lv_ed_domain_name.error = true

            t_return = false
          }
        }

        if (t_return) {
          if (FSCommUtil.gfn_length(this.lv_ed_o_addr_zipcode1) == 0 || FSCommUtil.gfn_length(this.lv_ed_o_addr_zipcode2) == 0) {
            FSCommUtil.gfn_validate(this, '우편번호를 입력해 주십시오.')
            this.lv_ed_o_addr_zipcode_new.error = true
            return false
          }

          let vO_addr_zipcode = FSCommUtil.gfn_trim(this.lv_ed_o_addr_zipcode_new.value)
          let vO_bldg_no = FSCommUtil.gfn_trim(this.lv_ed_o_bldg_no.value)

          if (FSCommUtil.gfn_length(vO_addr_zipcode) > 5) {
            FSCommUtil.gfn_validate(this, '직장 도로명주소로 입력하세요(우편번호 체계오류)')
            this.lv_ed_o_addr_zipcode_new.error = true
            return false
          }

          // 도로명주소일경우, 빌딩주소없으면 BP등록시 오류 발생 방지
          if (FSCommUtil.gfn_isNull(vO_bldg_no)) {
            FSCommUtil.gfn_validate(this, '직장 도로명주소로 입력하세요 (빌딩주소필수)')
            this.lv_ed_o_addr_zipcode_new.error = true
            this.lv_ed_o_bldg_no.error = true
            return false
          }

          if (FSCommUtil.gfn_length(this.lv_ed_o_addr_zipcode1) > 0) {
            if (FSCommUtil.gfn_length(this.lv_ed_o_zip_addr.value) == 0) {
              FSCommUtil.gfn_validate(this, '직장 우편번호 주소를 입력해 주십시오.')
              this.lv_ed_o_addr_zipcode_new.error = true
              return false
            }
          }

          // 직장주소있는데 직장명 없는경우
          if (FSCommUtil.gfn_length(this.lv_ed_o_name.value) == 0 && FSCommUtil.gfn_length(this.lv_ed_o_zip_addr.value) > 0) {
            FSCommUtil.gfn_validate(this, '직장명을 입력하세요.')
            this.lv_ed_o_name.error = true
            this.$refs['ed_o_name'].focus()
            return false
          }

          // 직장 전화번호 체크
          if (FSCommUtil.gfn_length(this.lv_ed_o_telno_area.value) == 0) {
            FSCommUtil.gfn_validate(this, '직장전화 지역번호를 입력해 주십시오.')
            this.lv_ed_o_telno_area.error = true
            this.$refs['ed_o_telno_area'].focus()
            return false
          } else {
            if (FSCommUtil.gfn_length(this.lv_ed_o_telno_station.value) == 0) {
              FSCommUtil.gfn_validate(this, '직장전화국 번호를 입력해 주십시오.')
              this.lv_ed_o_telno_station.error = true
              this.$refs['ed_o_telno_station'].focus()
              return false
            }

            if (FSCommUtil.gfn_length(this.lv_ed_o_telno_seq.value) == 0) {
              FSCommUtil.gfn_validate(this, '직장전화 일련번호를 입력해 주십시오.')
              this.lv_ed_o_telno_seq.error = true
              this.$refs['ed_o_telno_seq'].focus()
              return false
            }
          }

          // 국적, 거주국, 소득자구분, 거주자여부, 생계형구분 체크
          this.lv_cob_ctzn_natn_cd.error = false
          this.lv_cob_resi_natn_cd.error = false
          this.lv_cob_inc_earner_gb.error = false
          // this.lv_cob_life_gb.error           = false
          this.lv_cob_tax_gb.error = false
          this.lv_cob_rname_gb.error = false

          if (Number(this.lv_cob_rname_gb.value) > 6) {
            // 사업자등록번호
            if (this.lv_cob_rname_gb.value == '07') {
              if (this.lv_cob_ctzn_natn_cd.value != 'KR') {
                FSCommUtil.gfn_validate(this, '국적이 올바르지 않습니다.')
                this.lv_cob_ctzn_natn_cd.error = true
                return false
              }

              if (this.lv_cob_resi_natn_cd.value != 'KR') {
                FSCommUtil.gfn_validate(this, '거주국이 올바르지 않습니다.')
                this.lv_cob_resi_natn_cd.error = true
                return false
              }

              if (this.lv_cob_inc_earner_gb.value != '211') {
                FSCommUtil.gfn_validate(this, '소득자 구분이 올바르지 않습니다.')
                this.lv_cob_inc_earner_gb.error = true
                return false
              }

              if (this.lv_cob_tax_gb.value == '11') {
                FSCommUtil.gfn_validate(this, '과세구분이 올바르지 않습니다.')
                this.lv_cob_tax_gb.error = true
                return false
              }

              // 거래제한 실명번호종류
            } else if (this.lv_cob_rname_gb.value == '10' || this.lv_cob_rname_gb.value == '12' || this.lv_cob_rname_gb.value == '13') {
              FSCommUtil.gfn_validate(this, '거래가 제한된 실명번호종류 입니다.')
              return false

              // 고유번호
            } else if (this.lv_cob_rname_gb.value == '09') {
              if (this.lv_cob_ctzn_natn_cd.value != 'KR') {
                FSCommUtil.gfn_validate(this, '국적이 올바르지 않습니다.')
                this.lv_cob_ctzn_natn_cd.error = true
                return false
              }

              if (this.lv_cob_resi_natn_cd.value != 'KR') {
                FSCommUtil.gfn_validate(this, '거주국이 올바르지 않습니다.')
                this.lv_cob_resi_natn_cd.error = true
                return false
              }

              if (this.lv_cob_inc_earner_gb.value != '311') {
                FSCommUtil.gfn_validate(this, '소득자 구분이 올바르지 않습니다.')
                this.lv_cob_inc_earner_gb.error = true
                return false
              }

              if (this.lv_cob_tax_gb.value == '11') {
                FSCommUtil.gfn_validate(this, '과세구분이 올바르지 않습니다.')
                this.lv_cob_tax_gb.error = true
                return false
              }
            }
          } else {
            FSCommUtil.gfn_validate(this, '실명번호 종류가 올바르지 않습니다.')
            this.lv_cob_rname_gb.error = true
            return false
          }
        }
      }

      return true
    },

    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     ******************************************************************************/
    async fn_ReportPrint() {
      console.log('fn_ReportPrint....')

      let formList = []

      let FS000015 = { formId: 'FS000015' }
      FS000015.mappingData = {
        custNo: this.lv_ed_cust_no.value //고객번호
      }
      formList.push(FS000015)

      // if( (this.lv_rad_crd_int_yn1.value == 'Y' && (this.lv_cu150000_info.crd_int_yn1 == 'N' || FSCommUtil.gfn_isNull(this.lv_cu150000_info.crd_int_yn1) )) ||
      //     (this.lv_rad_crd_int_yn2.value == 'Y' && (this.lv_cu150000_info.crd_int_yn2 == 'N' || FSCommUtil.gfn_isNull(this.lv_cu150000_info.crd_int_yn2) ))
      //   ) {
      // 조건수정
      if (this.lv_rad_crd_int_yn2.value == 'Y' && (this.lv_bf_crd_int_yn2.value == 'N' || FSCommUtil.gfn_isNull(this.lv_bf_crd_int_yn2.value))) {
        if (this.lv_corp_gb == 'N') {
          // 개인일 경우에만 서식 출력되도록 조건 추가
          let FS000005 = { formId: 'FS000005' }

          // 마케팅 동의여부 "예" 일 경우
          if (this.lv_rad_crd_int_yn2.value == 'Y') {
            FS000005.mappingData = {
              adChk: this.lv_rad_crd_int_yn2.value,
              chkSms: this.lv_cob_mkt_cnvs_mth_sms_yn.value ? 'Y' : 'N',
              chkEmail: this.lv_cob_mkt_cnvs_mth_email_yn.value ? 'Y' : 'N',
              chkArs: this.lv_cob_mkt_cnvs_mth_tel_yn.value ? 'Y' : 'N',
              chkDm: this.lv_cob_mkt_cnvs_mth_pst_yn.value ? 'Y' : 'N',
              custNm: this.lv_cust_nm.value
            }

            // 연락방식 전부 승인일 경우
            if (this.lv_cob_mkt_cnvs_mth_sms_yn.value && this.lv_cob_mkt_cnvs_mth_email_yn.value && this.lv_cob_mkt_cnvs_mth_tel_yn.value && this.lv_cob_mkt_cnvs_mth_pst_yn.value) {
              FS000005.mappingData.chkAll = 'Y'
            }
          } else {
            FS000005.mappingData = {
              adChk: this.lv_rad_crd_int_yn2.value,
              custNm: this.lv_cust_nm.value
            }
          }
          formList.push(FS000005)
        }
      }
      FSInfoUtil.commReportInfo(this, formList)
    },

    /******************************************************************************
     * Function명 : fn_clear
     * 설명       : validation  초기화
     ******************************************************************************/
    fn_clear(param) {
      switch (param) {
        case 'appointCust':
          this.lv_ed_cust_no.error = false // 고객번호 validation 초기화
          break
        case 'agnt_chk':
          this.lv_rdo_agnt_chk_yn.error = false // 대리인여부  validation 초기화
      }
    }
  }
}
</script>
<style scoped></style>
